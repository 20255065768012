import { render, staticRenderFns } from "./InsertUsuarioView.vue?vue&type=template&id=752a9a70&scoped=true"
import script from "./InsertUsuarioView.vue?vue&type=script&lang=js"
export * from "./InsertUsuarioView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752a9a70",
  null
  
)

export default component.exports