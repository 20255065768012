<template>
    <v-card class="mx-auto card-login" max-width="320" height="440" elevation=8>
        <!-- Logo e título -->
        <v-card-title>
            <v-row class="linha-logo-titulo">
                <v-col cols="12" class="coluna-logo-titulo">
                    <v-img class="logo-titulo" :src=img_logo alt="Não encontrou o logo" width="47px"></v-img>
                </v-col>

                <v-col cols="12" class="coluna-titulo">
                    <v-card-subtitle>
                        <span v-if="Array.isArray(texto_abaixo_logo)">
                            <div v-for="(texto, i) in texto_abaixo_logo" :key="i">
                                <div class="texto-titulo"> {{ texto }}</div>
                            </div>
                        </span>
                        <p class="texto-titulo" v-else>{{ texto_abaixo_logo }}</p>
                    </v-card-subtitle>
                </v-col>
            </v-row>
        </v-card-title>

        <!-- Campos do login -->
        <v-card-text class="card-campos-login">
            <v-container>
                <v-divider class="divider-login-topo"></v-divider>

                <v-row class="linha-campos-login">
                    <v-col cols="12">
                        <v-alert v-if="error != undefined" color="#FBCC38" border="left" elevation="2" colored-border>
                            {{ error }}
                        </v-alert>
                    </v-col>

                    <v-col cols="12" class="coluna-campo-email">
                        <v-text-field ref="email" label="E-mail" v-model="email" placeholder="Informe o e-mail"
                            append-icon="mdi-email" color="#94896d" solo></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field type="password" ref="senha" label="Senha" placeholder="Informe Senha"
                            v-model="senha" append-icon="mdi-key-variant"
                            v-on:keyup.13="login(email, senha, 'CONSUMIDOR')" color="#94896d" solo></v-text-field>
                    </v-col>
                </v-row>

                <v-divider class="divider-login-bottom"></v-divider>
            </v-container>
        </v-card-text>

        <!-- Botões de ação -->
        <v-card-actions class="card-actions-botoes">
            <!-- Botão para logar no sistema -->
            <v-btn class="mr-5" color="#990000" dark width="120px" @click="login(email, senha)"
                :disabled="desativaBotaoLogar">
                <span class="texto-botao-entrar">Entrar</span>
            </v-btn>
        </v-card-actions>

        <!-- Painel para exibição de mensagens -->
        <v-card class="painel-exibicao-mensagens" v-if="alert.show" elevation="8" width="100%">
            <v-alert :type="alert.typeAlert" class="ma-0">
                {{ alert.msg }}
            </v-alert>
        </v-card>
    </v-card>
</template>

<script>
import mixin from "@/store/mixin.js";

export default {
    mixins: [mixin],
    data() {
        return {
            alert: {
                show: false,
                msg: '',
                typeAlert: ''
            },
            email: '',
            senha: '',
            desativaBotaoLogar: false,
            error: undefined,
        }
    },
    props: {
        img_logo: {
            type: String,
        },
        texto_abaixo_logo: {
            type: String | Array,
        },
    },
    computed: {
        emailRules() {
            return this.validaEmail();
        }
    },
    methods: {
        messageLogin() {
            // if(this.$store.state.alertMessage.alert != null){
            //     this.loginMessage(this.$store.state.alertMessage.alert)
            // }
        },
    },
}
</script>

<style scoped>
.card-login {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 15px;
    position: relative;
}

.linha-logo-titulo {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.coluna-logo-titulo {
    padding-top: 0px;
    padding-bottom: 0px;
}

.logo-titulo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    height: 100px;
    object-fit: contain;
}

.coluna-titulo {
    padding-top: 0px;
    text-align: center;
}

.texto-titulo {
    color: #000000;
    font-size: 12px;
    white-space: nowrap;
}

.card-campos-login {
    padding-top: 0px;
    padding-bottom: 0px;
}

.divider-login-topo {
    margin-bottom: 30px;
}

.linha-campos-login {
    margin-top: -50px;
    margin-bottom: -40px;
}

.coluna-campo-email {
    height: 62px;
}

.divider-login-bottom {
    margin-top: 15px;
    margin-bottom: 4px;
}

.card-actions-botoes {
    display: flex;
    justify-content: end;
    padding-bottom: 0px;
}

.texto-botao-entrar {
    color: #ffffff;
}

.painel-exibicao-mensagens {
    position: absolute;
    top: 105%;
}
</style>