import {getApi} from '@/network/ApiService';

export default {
  data() {
    return {};
  },
  methods: {
    async gerarPDF(url, id, nomeArquivo) {
      try {
        const response = await getApi(url, id, null, {
          responseType: 'blob',
        });
        if (response.status === 200) {
          const base64 = response.data;
          const filename = `${nomeArquivo}.pdf`;
          // Converte a string Base64 para um array de bytes
          const byteCharacters = window.atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Cria um objeto Blob a partir do array de bytes
          const blob = new Blob([byteArray], {type: 'application/pdf'});

          // Cria uma URL temporária para o Blob e inicia o download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          console.error(`Erro ao baixar o PDF. Status: ${response.status}`);
        }
      } catch (error) {
        console.error(`Falha ao baixar o PDF: ${error.message}`);
      }
    },
  },
};
