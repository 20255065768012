<template>
  <div>
    <edita-dados-v2 :idregistro="idregistro" :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro" :campos_formulario="dados" :showProgresso="showProgresso"
      @saveCompleted="saveCompleted" @close="close">
      <v-row>
        <v-col cols="8">
          <v-text-field type="text" label="Descrição:" v-model="dados.descricao" autofocus color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <CampoData titulo="Data Transferência" v-model="dados.dt_criacao" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select label="Conta Origem:" :items="listaContas" item-value="idconta" item-text="descricao"
            v-model="dados.idconta_origem" color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-select label="Conta Destino:" :items="listaContas" item-value="idconta" item-text="descricao"
            v-model="dados.idconta_destino" color="#94896d" dense />
        </v-col>
        <!-- <v-col cols="3">
          <v-select label="Forma Pagamento:" :items="listaFormasPagamento" item-value="idformapagamento"
            item-text="descricao" v-model="dados.idformapagamento" color="#94896d" dense />
        </v-col> -->
        <v-col cols="4">
          <v-text-field type="text" ref="valor" label="Valor:" v-model="dados.valor"
            @input="dados.valor = formatarMoeda(dados.valor)" color="#94896d" dense>
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>

        <v-col cols="4">
          <v-select label="Caixa:" :items="listaCaixas" item-value="idcaixa" item-text="descricao"
            v-model="dados.idcaixa" color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-select label="Contrato:" :items="listaContratos" item-value="idcontrato" item-text="titular"
            v-model="dados.idcontrato" color="#94896d" dense />
        </v-col>
      </v-row> -->
    </edita-dados-v2>
  </div>
</template>
<script>

import mixin from '@/store/mixin.js';
import EditaDadosV2 from '@/components/crud/EditaDadosV2.vue';
import TelaCarregando from '@/components/TelaCarregando.vue';
import { format, parseISO } from 'date-fns'

import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  components: {
    TelaCarregando,
    EditaDadosV2,
  },
  created() {
    this.initialize();
  },
  mixins: [mixin],
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Edição de transferência',



      url_consultar_dados: api.transferenciaConsultar,
      apiAtualizarRegistro: api.transferenciaAtualizar,
      nome_rota_listagem_dados: 'Transferencia',
      listaContas: [],
      listaFormasPagamento: [],
      listaContratos: [],
      listaCaixas: [],
      dados: {
        idtransferencia: '',
        descricao: '',
        valor: 0,
        idconta_origem: '',
        idconta_destino: '',
        idformapagamento: '',
        dt_criacao: '',
        idcaixa: '',
        idcontrato: '',
      },
      error: undefined,
    };
  },

  methods: {
    async initialize() {
      try {
        this.showProgresso = true;

        let consultaCaixa = await getApi(api.caixaListarTodas);
        let consultaContas = await getApi(api.contaListarTodas);
        let consultaFormasPagamento = await getApi(api.formaPagamentoListarTodas);
        let consultaContratos = await getApi(api.contratoListarTodos);
        let currentData = await getApi(this.url_consultar_dados + `/${this.idregistro}`);

        this.listaCaixas = consultaCaixa.data;
        this.listaContas = consultaContas.data;
        this.listaFormasPagamento = consultaFormasPagamento.data;
        this.listaContratos = consultaContratos.data;

        this.dados.idtransferencia = currentData.data.idtransferencia;
        this.dados.descricao = currentData.data.descricao;
        this.dados.valor = this.formatarMoeda(currentData.data.valor);
        this.dados.idconta_origem = currentData.data.idconta_origem;
        this.dados.idconta_destino = currentData.data.idconta_destino;
        this.dados.idformapagamento = currentData.data.idformapagamento;
        this.dados.dt_criacao = currentData.data.dt_criacao;
        this.dados.idcaixa = currentData.data.idcaixa;
        this.dados.idcontrato = currentData.data.idcontrato;
        this.showProgresso = false;
      } catch (err) {
        var msgErro = err.response.data.err;
        this.error = msgErro;
      };
    },
    saveCompleted() {
      this.$router.push({ name: this.nome_rota_listagem_dados  });
    },
    close() {
      this.$router.push({ name: this.nome_rota_listagem_dados });
    },
  },
};
</script>

<style scoped>
.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>
@/network