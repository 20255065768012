<template>
    <div>
      <!-- Menu -->
      <v-navigation-drawer 
        app 
        v-model="drawer" 
        class="barra-navegacao"
        :width="265"
      >
        <v-list 
          v-for="(item, i) in items"
          :key="i"
          dense 
          shaped
        >
          <v-list-group v-if="item.submenu && item.showMenu">
            <!-- Agrupamento -->
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="#990000">{{ item.icone }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title style="color: #94896d">{{ item.dscmenu }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- Submenus -->
            <v-list-item link 
              v-for="(itemSubmenu, index) in item.submenu" 
              :key="index" 
              v-show="itemSubmenu.showMenu"
              style=" padding-left: 20px;"
            >
              <v-list-item-icon style="margin-right: 2px;">
                <v-icon color="#990000">{{ itemSubmenu.icone }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content @click="acaoMenu(itemSubmenu.menu)">
                <v-list-item-title>
                  <span style="color: #94896d">{{ itemSubmenu.dscmenu }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          
          <!-- Menu que não possui agrupamento -->
          <template v-else>
              <v-list-item v-show="item.showMenu" @click="acaoMenu(item.menu)">
                <v-list-item-icon>
                  <v-icon color="#990000">{{ item.icone }}</v-icon>
                </v-list-item-icon>
                
                <v-list-item-title style="color: #94896d">{{ item.dscmenu }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>

        <v-list 
          dense 
          shaped
        >
          <v-list-item
            link
          >
            <v-list-item-icon>
              <v-icon color="#990000"> mdi-exit-to-app </v-icon>
            </v-list-item-icon>

            <v-list-item-content
              @click="logoff()"
            >
              <v-list-item-title>
                <span style="color: #94896d">Sair</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Barra superior com logo e botão de menu -->
      <v-app-bar 
        absolute 
        style="height: 80px;"
        extended
      >
        <img :src=img_logo />

        <v-spacer></v-spacer>
        
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="ml-auto"></v-app-bar-nav-icon>
      </v-app-bar>
    </div>
</template>

<script>
import mixin from "@/store/mixin.js";

export default {
  mixins: [mixin],
  created () {
      this.initialize ();    
  },
  props: {
    img_logo: {
      type: String,
    },
    items: {
      type: Array,
    },
  }, 
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    initialize () { },
    acaoMenu (pMenu) {
      this.executaMenu(pMenu);
    },
    logoff () {
      // localStorage.removeItem("token");
      this.$router.push({name: 'Login'});
    }
  }  
}
</script>

<style scoped>
  .barra-navegacao {
    background-color: #f5f4ec;
  }

  .logo-menu {
    display: flex; 
    justify-content: center;
  }
</style>