<template>
  <div>
    <v-card v-show="!showProgresso" max-width="1024" class="mx-auto my-10" style="background-color: #f8f9fa">
      <v-card-title style="background-color: #94896d">
        <span class="text-h6" style="color: #ffffff">
          Mebros da família
        </span>
      </v-card-title>

      <!-- Campos do formulário -->
      <v-card-text style="padding-bottom: 0px">
        <v-container>
          <v-row>
            <!-- Campo descrição -->
            <v-col cols="12">
              <v-alert v-if="error != undefined" color="#FBCC38" elevation="2" colored-border>
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table :headers="headers" :items="dadosRelacoes" sort-by="nome" class="elevation-1">
                <!-- Caixa de diálogo para confirmação da exclusão -->
                <template v-slot:[`top`]>
                  <v-dialog v-model="dialogDelete" max-width="555px">
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ mensagem_exclusao }}
                      </v-card-title>

                      <!-- Botões para cancelamento ou confirmação da exclusão -->
                      <v-card-actions>
                        <v-spacer />

                        <v-btn color="#f5f4ec" dark @click="dialogDelete = false">
                          <span style="color: #990000">Não</span>
                        </v-btn>

                        <v-btn color="#990000" dark @click="deleteItemConfirm">
                          <span style="color: #ffffff">Sim</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- Caixa de dialogo de edição/adição -->
                  <v-dialog v-model="dialogEdicao" max-width="555px">
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ mensagem_edicao }}
                      </v-card-title>
                      <v-card-subtitle>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete label="Pessoa" :items="listaPessoas" item-text="nome" item-value="idpessoa"
                              v-model="itemEdicao.pessoa_id" color="#94896d" />
                          </v-col>
                          <v-col cols="12">
                            <v-select label="Relação" :items="listaRelacao" item-text="relacao_superior"
                              item-value="idrelacao" v-model="itemEdicao.idrelacao" color="#94896d" />
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="#f5f4ec" dark @click="dialogEdicao = false">
                          <span style="color: #990000">Cancelar</span>
                        </v-btn>

                        <v-btn color="#990000" dark @click="salvarRelacao">
                          <span style="color: #ffffff">Salvar</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- Tabela -->
                  <v-row>
                    <v-col cols="3" offset="9">
                      <v-btn color="#990000" dark class="mb-2" @click="abrirDialogoAdicionarRelacao()">
                        Adicionar Relação
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.pessoa_nome="{ item }">
                  {{ item.pessoa_nome }}
                  <v-icon small class="mr-2" @click="abrirCadastroPessoa(item)">
                    mdi-open-in-new
                  </v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
  </div>
</template>

<script>

import TelaCarregando from '@/components/TelaCarregando.vue';
import { api } from '@/network/Api';
import { getApi, excluirApi, postApi } from '@/network/ApiService';

export default {
  components: {
    TelaCarregando
  },
  props: {
    idpessoa: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      showProgresso: false,
      error: undefined,
      dialog: false,
      mensagem_exclusao: 'Deseja realmente excluir esta relação?',
      dialogDelete: false,
      mensagem_edicao: 'Relação',
      dialogEdicao: false,
      headers: [
        { text: 'Nome', value: 'pessoa_nome' },
        { text: 'Relação', value: 'relacao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      pessoa: {},
      dadosRelacoes: [],
      listaRelacao: [],
      listaPessoas: [],
      itemEdicao: {}
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdicao(val) {
      val || this.closeEdit();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.showProgresso = true;

      getApi(api.pessoaRelacaoConsultar, this.idpessoa)
        .then((res) => {
          this.dadosRelacoes = res.data;
          setTimeout(() => { this.showProgresso = false; }, 100);
        })
        .catch((err) => {
          setTimeout(() => { this.showProgresso = false; }, 100);
        });
    },
    abrirDialogoAdicionarRelacao() {
      this.dialogEdicao = true;

      getApi(api.pessoaRelacaoListarTodas)
        .then((res) => {
          this.listaRelacao = res.data;
        })
        .catch((err) => {
          setTimeout(() => { this.showProgresso = false; }, 100);
        });

      getApi(api.pessoaConsultar, this.idpessoa)
        .then((res) => {
          this.pessoa = res.data;
          this.mensagem_edicao = 'Relação para: ' + this.pessoa.nome;
        })
        .catch((err) => {
          setTimeout(() => { this.showProgresso = false; }, 100);
        });

      getApi(api.pessoaListarTodas)
        .then((res) => {
          this.listaPessoas = res.data;
          setTimeout(() => { this.showProgresso = false; }, 100);
        })
        .catch((err) => {
          setTimeout(() => { this.showProgresso = false; }, 100);
        });
    },
    salvarRelacao() {
      let dadosRelacao = {
        idrelacao: this.itemEdicao.idrelacao,
        idpessoaInferior: this.itemEdicao.pessoa_id,
        idpessoaSuperior: this.idpessoa
      };

      if (this.itemEdicao.idpessoarelacao == null) {
        postApi(api.pessoaRelacaoCriar, null, dadosRelacao)
          .then((res) => {
            setTimeout(() => {
              this.showProgresso = false;
              this.dialogEdicao = false;
            }, 100);
          })
          .catch((err) => {
            setTimeout(() => { this.showProgresso = false; }, 100);
          });
      } else {
        postApi(api.pessoaRelacaoEditar, this.itemEdicao.idpessoarelacao, dadosRelacao)
          .then((res) => {
            setTimeout(() => {
              this.showProgresso = false;
              this.dialogEdicao = false;
            }, 100);
          })
          .catch((err) => {
            setTimeout(() => { this.showProgresso = false; }, 100);
          });
      }

    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      excluirApi(api.pessoaRelacaoExcluir, this.editedItem.idpessoarelacao)
        .then(() => {
          this.closeDelete();
        })
        .catch((err) => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta =
              'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    editItem(item) {
      this.abrirDialogoAdicionarRelacao();
      this.itemEdicao = item;
    },
    closeEdit() {
      this.itemEdicao = {};
      this.initialize();
    },

    close() {
      this.dialog = false;
      this.primarykey = -1;
      this.clearForm();
      this.initialize();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.initialize();
      this.mostra_botao_cadastrar = true;
    },
    abrirCadastroPessoa(item) {
      const routeData = this.$router.resolve({ path: '/pessoas/editar/' + item.pessoa_id });
      window.open(routeData.href, '_blank');
    }
  },
};
</script>

<style scoped>
.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>
@/network