<template>
    <div>
        <insere-dados 
            :titulo_formulario="titulo_formulario"
            :apiInserirRegistro="apiInserirRegistro"
            :campos_formulario="array_campos_formulario"
            @saveCompleted="saveCompleted"
            @close="close"
        >
            <p style="margin-bottom: -20px; color: #94896d;">* {{array_campos_formulario[0].label}}:</p>
                                    
            <v-text-field 
                type="text"
                ref="nome"
                v-model="array_campos_formulario[0].valor"
                autofocus
                color="#94896d"
                >
            </v-text-field>

            <p style="margin-bottom: -20px; color: #94896d;">* {{array_campos_formulario[1].label}}:</p>
                                    
            <v-text-field 
                type="text"
                ref="cnpj"
                v-model="array_campos_formulario[1].valor"
                v-mask="'##.###.###/####-##'"
                color="#94896d"
                >
            </v-text-field>

            <p style="margin-bottom: -20px; color: #94896d;">* {{array_campos_formulario[2].label}}:</p>
                                    
            <v-text-field 
                type="text"
                ref="endereco"
                v-model="array_campos_formulario[2].valor"
                color="#94896d"
                >
            </v-text-field>

            <p style="margin-bottom: -20px; color: #94896d;"> {{array_campos_formulario[3].label}}:</p>
                                    
            <v-text-field 
                type="text"
                ref="celular"
                v-model="array_campos_formulario[3].valor"
                v-mask="['(##) #####-####']"
                color="#94896d"
                >
            </v-text-field>

            <p style="margin-bottom: -20px; color: #94896d;"> {{array_campos_formulario[4].label}}:</p>
                                    
            <v-text-field 
                type="text"
                ref="telefone"
                v-model="array_campos_formulario[4].valor"
                v-mask="['(##) ####-####']"
                color="#94896d"
                >
            </v-text-field>
            
            <p style="margin-bottom: -20px; color: #94896d;"> {{array_campos_formulario[5].label}}:</p>
            
            <v-text-field 
                type="text"
                ref="email"
                v-model="array_campos_formulario[5].valor"
                color="#94896d"
                :rules="emailRules"
            >
            </v-text-field>
            
            <p style="margin-bottom: -5px; color: #94896d;"> {{array_campos_formulario[7].label}}:</p>

            <v-file-input
                v-model="array_campos_formulario[7].valor"
                placeholder="Anexar logo"
                label="Incluir arquivo"
                multiple
                prepend-icon="mdi-paperclip"
                @change="handleFileChange"
                accept="image/*"
            >
                <template v-slot:selection="{ text }">
                    <v-chip
                        small
                        label
                        color="primary"
                    >
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>
        </insere-dados>
    </div>
</template>

<script>
    import mixin from '@/store/mixin.js';
    import InsereDados from '@/components/crud/InsereDados.vue';
    import {api} from '@/network/Api';

    export default {
        mixins:[mixin],
        components: {
            InsereDados
        },
        data () {
            return {
                titulo_formulario: 'Cadastro de comunidade',
                apiInserirRegistro: api.comunidadeCriar,
                nome_rota_listagem_dados: 'Comunidades',
                array_campos_formulario: [
                    { label: 'Nome', nome_campo: 'nome', nome: '', obrigatorio: true },
                    { label: 'CNPJ', nome_campo: 'cnpj', cnpj: '', obrigatorio: true },
                    { label: 'Endereço', nome_campo: 'endereco', endereco: '', obrigatorio: true },
                    { label: 'Celular', nome_campo: 'celular', celular: '', obrigatorio: false },
                    { label: 'Telefone', nome_campo: 'telefone', telefone: '', obrigatorio: false },
                    { label: 'E-mail', nome_campo: 'email', email: '', obrigatorio: true },
                    { label: 'Logo', nome_campo: 'logo_url', logo_url: '', obrigatorio: false },
                    { label: 'Logo', nome_campo: 'logo_image', logo_image: '', obrigatorio: false },
                ],
            }
        },
        computed: {
            emailRules() {
                return this.validaEmail();
            }
        },
        methods: {
            handleFileChange(event) {
                const receberArquivo = this.array_campos_formulario[7].valor;
                if (receberArquivo.length > 0) {
                    this.converteParaBase64(receberArquivo[0]);
                }
            },
            converteParaBase64(pArquivo) {
                var carregarImagem = pArquivo;
                var lerArquivo = new FileReader();
                lerArquivo.onload = (arquivoCarregado) => {
                    this.array_campos_formulario[7].valor = arquivoCarregado.target.result;
                }
                
                lerArquivo.readAsDataURL(carregarImagem);
            },
            saveCompleted(item) {
                this.$router.push({name: 'Comunidades'});
            },
            close() {
                this.$router.push({name: 'Comunidades'});
            },
            
        }
    }
</script>

<style scoped>
</style>