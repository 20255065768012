import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        usuario_logado: {
            usuario: "",
            token: "",
            nome: "",
            email: ""
        },
        error:{
            msgError:'Página não encontrada!',
            pathError: '/'
        },
        alertMessage:{
            status: null
        }
    },
    getters:{
        retornaInfoUsuario: (state) => {
            let usuario = state.usuario_logado.usuario;
            let nome = state.usuario_logado.nome;
            let email = state.usuario_logado.email;

            return {
                usuario, 
                nome,
                email,
            }
        }
    },
    mutations:{
        resetaLogin: state => {
            state.usuario_logado = {
                usuario: null,
                token: "",
                nome: "",
                email: "",
            }
            localStorage.clear('usuarioLogado')
            localStorage.clear('token')
            localStorage.clear('usuario')
        },
        setaUsuario: (state, payload) => {
            state.usuario_logado = payload.usuario_logado;
        }
    },
    actions:{
        resetaLogin({commit}){
            commit('resetaLogin')
        }
    }
})