<template>
  <v-row justify="center">
    <v-dialog v-model="dialogo" persistent max-width="1024">
      <v-card>
        <v-card-title class="titulo__historico__renovacao">
          Renovação de contrato
        </v-card-title>
        <v-card-text v-if="contratoDisponivel">
          <v-row class="mt-4 mb-2">
            <v-col cols="4">
              <v-text-field disabled dense label="Titular do contrato" v-model="contrato.nome_contrato" />
            </v-col>
            <v-col cols="4">
              <CampoData disabled titulo="Validade atual do contrato" v-model="contrato.data_validade_fim" />
            </v-col>
            <v-col cols="4">
              <v-text-field disabled dense label="Faixa atual do contrato" v-model="contrato.faixa.descricao" />
            </v-col>
          </v-row>

          <!--  -->
          <div class="subtitulo__historico__renovacao mb-2">
            <span>
              Processo de renovação
            </span>
          </div>
          <v-row class="mt-4 mb-2">
            <v-col cols="6">
              <v-autocomplete dense label="Forma de pagamento para a renovação:" v-model="renovacao.formaPagamento"
                item-value="idformapagamento" item-text="descricao" :items="listaFormasPagamento" color="#94896d"
                :rules="requiredRule" autofocus clearable />
            </v-col>

            <v-col cols="6">
              <v-autocomplete dense label="Conta para a renovação:" v-model="renovacao.conta" item-value="idconta"
                item-text="descricao" :items="listaContas" color="#94896d" :rules="requiredRule" autofocus clearable />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="5">
              <v-autocomplete label="Faixa de contrato para a renovação:" v-model="renovacao.faixaContrato"
                :items="listFaixas" item-value="idfaixacontrato" item-text="descricao" color="#94896d"
                :rules="requiredRule" autofocus clearable />
            </v-col>
            <v-col cols="5">
              <v-autocomplete label="Ano para a renovação:" v-model="renovacao.ano" :items="listaAnos" color="#94896d"
                :rules="requiredRule" autofocus clearable />
            </v-col>
            <v-col cols="2">
              <v-btn @click="consultarAnuidade()">Consultar</v-btn>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col v-if="valorAnuidade?.descricao" cols="12">
              <span>A consulta retornou: {{ valorAnuidade.descricao }} com o valor </span>
              <money disabled class="text-field-money" v-model="valorAnuidade.valor" v-bind="money" />
            </v-col>
            <v-col v-else cols="12">
              {{ erroConsulta }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" style="display: flex; align-items: center;">
              <span style="    font-size: 1.1rem; margin-right: 22px;">Selecione a quantidade de parcelas a serem
                geradas: </span>
              <v-autocomplete v-model="renovacao.quantidadeParcelas" :items="listaParcelas" color="#94896d"
                :rules="requiredRule" autofocus clearable />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="fecharDialogo()">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="efetivarRenovacao()">
            Renovar o contrato
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { api } from '@/network/Api';
import { getApi, postApi } from '@/network/ApiService';

export default {

  props: {
    dialogoVisivel: {
      type: Boolean,
      default: false
    },
    idcontrato: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      dialogo: false,
      contrato: null,
      contratoDisponivel: false,
      listaFaixas: [],
      renovacao: {
        faixaContrato: null,
        ano: new Date().getFullYear() + 1,
        conta: null,
        formaPagamento: null,
        quantidadeParcelas: 12,
      },
      listFaixas: [],
      listaAnos: [],
      listaFormasPagamento: [],
      listaContas: [],
      valorAnuidade: {},
      listaParcelas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      erroConsulta: ""
    }
  },
  computed: {
    requiredRule() {
      return [
        v => !!v || "Obrigatório",
      ];
    },
  },
  created() {
    this.initialize();
    this.gerarListaAnosProximos();
  },
  methods: {
    async initialize() {
      try {
        let resposta = await getApi(api.contratoConsultar, this.$route.params.id);
        this.contrato = resposta.data;

        let respostaFaixas = await getApi(api.faixaContratoListarTodas);

        this.renovacao.faixaContrato = this.contrato.faixa.idfaixacontrato;
        this.listFaixas = respostaFaixas.data;

        let respostaConta = await getApi(api.contaListarTodas);
        this.listaContas = respostaConta.data;

        let respostaFormaPagamento = await getApi(api.formaPagamentoListarTodas);
        this.listaFormasPagamento = respostaFormaPagamento.data;

        this.renovacao.conta = this.contrato.idconta;
        this.renovacao.formaPagamento = this.contrato.idformapagamento_padrao;

        this.contratoDisponivel = true;
      } catch (erro) {
        console.error(erro);
      }
    },
    fecharDialogo() {
      this.$emit('fecharRenovacao');
    },
    async efetivarRenovacao() {
      try {
        this.erroConsulta = null;
        let respostaValorAnuidade = await postApi(api.contratoRenovar, this.idcontrato, this.renovacao);
        this.valorAnuidade = respostaValorAnuidade.data;
        this.fecharDialogo();
      }
      catch (e) {
        this.erroConsulta = e.response.data.message;
      }
    },
    gerarListaAnosProximos() {
      const currentYear = new Date().getFullYear();
      const anos = [];

      for (let i = -5; i <= 5; i++) {
        anos.push(currentYear + i);
      }

      this.listaAnos = anos;
    },
    async consultarAnuidade() {
      try {
        let respostaValorAnuidade = await postApi(api.faixaValorConsultarValor, this.renovacao.faixaContrato, this.renovacao);
        this.valorAnuidade = respostaValorAnuidade.data;
        this.erroConsulta = null;
      } catch (e) {
        console.error(e.response.data.message);
        this.valorAnuidade = null;
        this.erroConsulta = e.response.data.message;
      }
    },
  },
  watch: {
    dialogoVisivel(valor) {
      this.dialogo = valor;
    }
  }
}
</script>

<style scoped>
.titulo__historico__renovacao {
  font-size: 1.4rem;
  padding: 4px;
  margin: 0;
  background-color: #928a6dd9;
  color: #FFFFFF;
}

.subtitulo__historico__renovacao {
  font-size: 1.3rem;
  padding: 12px;
  margin: 0;
  background-color: #d5cfb9d9;
  color: #594e51;
  border-top: gray solid 1px;
}
</style>
