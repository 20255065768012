import customFetch from '../libs/customFetch';
import { realizarLogin, realizarLogout } from '@/network/ApiService';

export default {
  data() {
    return {
      alert: {
        show: false,
        msg: '',
        typeAlert: 'success'
      },
    }
  },
  methods: {
    formataDataParaPtBr(date) {
      if (!date) return null

      // const [data] = date.split('T');
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`
    },
    // Função para logar
    login(pUsuario, pSenha) {
      this.desativaBotaoLogar = true;
      if (pUsuario !== '' && pSenha !== '') {
        realizarLogin(pUsuario.trim(), pSenha.trim())
          .then(res => {
            this.loginMessage('success');
            setTimeout(() => {
              this.$router.push({ path: '/home', meta: { isAuth: true } })
                .catch(() => { this.loginMessage(500); });
            }, 1000)
          })
          .catch(err => {
            this.loginMessage(err.status, err.message);
            this.desativaBotaoLogar = false;
          })
      } else {
        this.loginMessage(500);
      }
    },
    logout() {
      realizarLogout()
        .then(res => {
          this.$router.push({ path: '/login', meta: { isAuth: false } }).catch(() => { this.loginMessage(500); });
        })
        .catch(err => {
          if (err.message != null && err.message != undefined) {
            this.loginMessage(err.status, err.message);
            this.desativaBotaoLogar = false;
          }
        })
    },
    // Função para exibir mensagem de login, tanto para sucesso quanto para erro
    loginMessage(login, message = '') {
      this.alert.show = true
      clearTimeout()
      switch (login) {
        case 'success':
          this.alert.typeAlert = "success"
          this.alert.msg = 'Login realizado com sucesso!'
          break
        case 'logoff':
          this.alert.typeAlert = "success"
          this.alert.msg = 'Logoff realizado com sucesso!'
          break
        case 401:
          this.alert.typeAlert = "error"
          this.alert.msg = 'Usuário ou senha inválidos!'
          break
        case 404:
          this.alert.typeAlert = "error"
          this.alert.msg = 'Usuário ou senha inválidos!'
          break
        case 500:
          this.alert.typeAlert = "error";
          this.alert.msg = message != '' ? message : 'Preencher os campos para login!';
          break
        case 'expired':
          this.alert.typeAlert = "error"
          this.alert.msg = 'Sua sessão expirou!'
          break
        case 'message':
          this.alert.typeAlert = "error"
          this.alert.msg = message
          break
      }
      setTimeout(() => {
        this.alert.show = false
      }, 3000)
    },
    executaMenu(pMenu) {
      this.$router.push({ name: pMenu });
    },
    // Função para verificar se o usuario está logado, é utilizada no tráfego do portal
    // checkToken () {
    //     let tokenLocal = localStorage.getItem('token') !== null ? localStorage.getItem('token') : undefined;
    //     if(!tokenLocal){
    //         this.redirect()
    //     } else {
    //         this.allowDataLoad = true;
    //     }
    // },
    // redirect() {
    //     // Redirecionamos para o login de acordo com o tipo do usuário
    //     let role = window.localStorage.getItem('usuario');
    //     switch (role) {
    //         case 'consumidor':
    //             this.$router.push('/');
    //             this.$store.state.alertMessage.alert = 'expired';
    //             this.$store.dispatch('resetaLogin');
    //             break;
    //         case 'produtor':
    //             this.$router.push('/login/admin');
    //             this.$store.state.alertMessage.alert = 'expired';
    //             this.$store.dispatch('resetaLogin');
    //             break;
    //         case 'operacao':
    //             this.$router.push('/login/admin');
    //             this.$store.state.alertMessage.alert = 'expired';
    //             this.$store.dispatch('resetaLogin');
    //             break;
    //     }
    // },
    // Função para formatar os valores dos relatórios
    // divisao(valor) {
    //     return (valor / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace(/^[^\d]+/, '$');
    // },
    // Função responsável por converter a data inserida nos filtros para o formatdo esperado pelo backend
    async convertDate(date) {
      const dataObj = new Date(date);
      const ano = dataObj.getFullYear();
      const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
      const dia = String(dataObj.getDate()).padStart(2, '0');
      const hora = String(dataObj.getHours()).padStart(2, '0');
      const minutos = String(dataObj.getMinutes()).padStart(2, '0');
      const segundos = String(dataObj.getSeconds()).padStart(2, '0');

      return `${ano}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
    },
    retornaDataPtBr(pDataHora) {
      // Separa a data e a hora
      let partes = pDataHora.split(" ");

      // Obtém a hora e a data em partes separadas
      let hora = partes[1];
      let data = partes[0];

      // Formata a data no formato dia/mês/ano
      let arrayAnoMesDia = data.split("-");
      let dataFormatada = arrayAnoMesDia[2] + "/" + arrayAnoMesDia[1] + "/" + arrayAnoMesDia[0];

      // Retorna a hora seguida da data formatada
      return dataFormatada + " - " + hora;
    },
    validaEmail() {
      return [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email inválido'
      ];
    },
    async enviaEmailRecuperarSenha(bodyRequest) {
      let result = await customFetch('cashless/recuperarSenha', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-type': 'application/json' },
        body: bodyRequest
      }, process.env.VUE_APP_RECUPERAR_SENHA)
      let jsonResponse = await result.json();
      return jsonResponse;
    },
    async getKeysRecaptcha() {
      let result = await customFetch('base/getKeysRecaptcha', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-type': 'application/json' }
      }, process.env.VUE_APP_RECUPERAR_SENHA)
      let jsonResponse = await result.json();
      return jsonResponse;
    },
    removerAcentos(texto) {
      return texto
        .normalize('NFD') // Normaliza a string para decompor os caracteres acentuados em não acentuados
        .replace(/[\u0300-\u036f]/g, ''); // Remove os caracteres acentuados (que agora foram decompostos)
    },
    verifica_periodo_reembolso(pDataIni, pDataFim) {
      const dataAtual = new Date();
      const dataInicio = new Date(pDataIni);
      const dataFim = new Date(pDataFim);

      if (dataAtual >= dataInicio && dataAtual <= dataFim) {
        return true;
      } else {
        return false;
      }
    },
    removeMascaraCPF(pCpf) {
      // Remover a máscara (pontos e traços) do CPF
      const cpfSemMascara = pCpf.replace(/[.-]/g, "");

      // Aplicar o replace no CPF sem a máscara
      return cpfSemMascara.replace("12345678909", "98765432101");
    },
    formataCPF(cpf) {
      // Função que adiciona a máscara em um CPF
      if (cpf !== null) {
        // retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");
        //realizar a formatação...
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        return cpf
      }

      if (cpf === null) {
        return 'Sem informação'
      }
    },
    validaCPF(cpf) {
      // Remove todos os caracteres não numéricos
      cpf = cpf.replace(/[^\d]+/g, '');

      // O CPF deve ter 11 dígitos
      if (cpf.length !== 11) {
        return false;
      }

      // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
      if (/^(\d)\1+$/.test(cpf)) {
        return false;
      }

      // Calcula o primeiro dígito verificador
      let soma = 0;
      for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let resto = 11 - (soma % 11);
      let digitoVerificador1 = (resto === 10 || resto === 11) ? 0 : resto;

      // Calcula o segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
      }
      resto = 11 - (soma % 11);
      let digitoVerificador2 = (resto === 10 || resto === 11) ? 0 : resto;

      // Verifica se os dígitos verificadores calculados são iguais aos dígitos originais
      return (parseInt(cpf.charAt(9)) === digitoVerificador1 && parseInt(cpf.charAt(10)) === digitoVerificador2);
    },
    verificaNomeCompleto(nome) {
      // Remove espaços em branco extras no início e no final do nome
      nome = nome.trim();

      // Divide o nome em palavras separadas por espaços em branco
      const palavras = nome.split(/\s+/);

      // Verifica se há pelo menos duas palavras (nome e sobrenome)
      return palavras.length >= 2;
    },
    formatarMoeda(pValor) {
      // Remove caracteres não numéricos
      let valorNumerico = pValor.replace(/\D/g, '');

      // Formata para o formato de moeda
      valorNumerico = parseFloat(valorNumerico / 100).toFixed(2);

      // Formata para o formato brasileiro
      return parseFloat(valorNumerico).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    formataDataParaPtBr(date) {
      if (!date) return null

      // const [data] = date.split('T');
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`
    },
    limitarDigitos(pInput, pMaxDigitos) {
      console.log(pInput);

      // Obter o valor atual
      let valor = pInput;

      // Remover todos os caracteres não numéricos
      valor = valor.replace(/\D/g, '');

      console.log('Tamanho: ' + valor.length);
      console.log('Tamanho máximo: ' + pMaxDigitos);

      // Limitar ao número máximo de dígitos
      if (valor.length > pMaxDigitos) {
        console.log('É maior');
        valor = valor.slice(0, pMaxDigitos);
        console.log('Novo valor: ' + valor);
      }

      return valor;
    }
  }
}