<template>
  <div>
    <v-card max-width="1024" class="mx-auto my-10" style="background-color: #f8f9fa">
      <!-- Título do formulário -->
      <v-card-title style="background-color: #94896d;>">
        <span class="text-h6" style="color: #ffffff">
          {{ titulo_formulario }}
        </span>
      </v-card-title>
      <hr />
 

      <!-- Campos do formulário -->
      <v-card-text style="padding-bottom: 0px">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="error != undefined" color="#efb300" elevation="2" colored-border
                style="background-color: #990000; color: white">
                {{ error }}
              </v-alert>

              <slot></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- Botões para salvar ou cancelar o processo de cadastro -->
      <v-card-actions>
        <v-spacer />

        <!-- Botão para cancelar o processo e não salvar os dados no banco de dados -->
        <v-btn color="#f5f4ec" dark class="mb-2" @click="close">
          <span style="color: #990000">Cancelar</span>
        </v-btn>

        <!-- Botão para salvar os dados do usuário no banco de dados -->
        <v-btn color="#990000" dark class="mb-2 mr-5" @click="salvar">
          <span style="color: #ffffff">Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { postApi } from '@/network/ApiService';
export default {
  props: {
    titulo_formulario: {
      type: String,
      required: true,
    },
    apiInserirRegistro: {
      type: String,
      required: true,
    },

    campos_formulario: {
      type: Array | Object,
      required: true,
    },
  },
  data() {
    return {
      error: undefined,
    };
  },
  methods: {
    salvar() {
      let validacao = true;
      let formulario = this.campos_formulario;

      if (validacao) {
        if (formulario.valor) {
          formulario.valor = formulario.valor.replace(/\./g, "");
          formulario.valor = formulario.valor.replace(",", ".");
        } else {
          formulario.valor = 0;
        }


        postApi(this.apiInserirRegistro, null, formulario)
          .then((res) => {
            this.$emit('saveCompleted', res.data);
          })
          .catch((err) => {
            console.error(err);
            if(err?.response?.data?.message) {
              this.error = err.response.data.message;
            } else {
              this.error = 'Falha no servidor contate o administrador do sistema!';
            }
            setTimeout(() => { this.error = undefined; }, 4000);
          });
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
