<template>
  <v-container style="padding: 0px;">
    <header v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md" style="background-color: blue;">
      <menu-mobile :items="itensMenu" :img_logo="require('@/assets/img/logo_ieclb_mobile.png')" style="cursor: pointer;"></menu-mobile>
    </header>
    <main>
      <div class="menu_pagina" v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
        <menu-web :items="itensMenu" :img_logo="require('@/assets/img/logo_ieclb_mobile.png')" style="cursor: pointer;"></menu-web>
      </div>

      <v-container fluid fill-height class="box corpo_pagina">
        <v-row align="center" justify="center">
          <v-col>
            <router-view style="overflow: hidden;" />
          </v-col>
        </v-row>
      </v-container>
    </main>

    <footer class="box rodape_pagina">
      <footer-page :texto_rodape="'Igreja Evangélica de Confissão Luterana'"></footer-page>
    </footer>
  </v-container>
</template>

<script>
import MenuWeb from '../components/sidemenu/MenuWeb.vue'
import MenuMobile from '../components/sidemenu/MenuMobile.vue'
import FooterPage from '../components/footer/FooterPage.vue'

export default {
  name: 'HomeView',
  components: {
    MenuWeb,
    MenuMobile,
    FooterPage
  },
  created() {
    var larguraDaTela = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // alert(larguraDaTela);
  },
  data() {
    return {
      itensMenu: [
        {
          showMenu: true,
          icone: 'mdi-office-building-cog-outline',
          dscmenu: 'Administração',
          menu: 'Administracao',
          submenu: [
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Perfis',
              menu: 'Perfis',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Usuários',
              menu: 'Usuarios',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Comunidades',
              menu: 'Comunidades',
            }
          ]
        },
        {
          showMenu: true,
          icone: 'mdi-card-account-details-outline',
          dscmenu: 'Cadastros',
          menu: 'Cadastros',
          submenu: [
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Faixas',
              menu: 'FaixasContrato',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Tabelas de preço',
              menu: 'TabelasPreco',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Contratos',
              menu: 'Contratos',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Contas',
              menu: 'Contas',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Caixas',
              menu: 'Caixas',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Formas de pagamento',
              menu: 'FormasPagamento',
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Pessoas',
              menu: 'Pessoas',
            }
          ]
        },
        {
          showMenu: true,
          icone: 'mdi-bank-transfer',
          dscmenu: 'Transferêcia',
          menu: 'Transferencia'
        },
        {
          showMenu: true,
          icone: 'mdi-book-account-outline',
          dscmenu: 'Pagamentos',
          menu: 'Pagamentos'
        },
        {
          showMenu: true,
          icone: 'mdi-file-chart-outline',
          dscmenu: 'Relatórios',
          menu: 'Relatorios',
          submenu: [
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Aniversariantes',
              menu: 'RelatorioAniversario'
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Batismo',
              menu: 'RelatorioBatismo'
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Confirmação',
              menu: 'RelatorioConfirmacao'
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Faixa etária',
              menu: 'RelatorioFaixaEtaria'
            },
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Inadimplentes',
              menu: 'RelatorioInadimplentes'
            }, 
            {
              showMenu: true,
              icone: 'mdi-menu-right',
              dscmenu: 'Membros por faixa',
              menu: 'RelatorioMembrosPorFaixa'
            },

            // {
            //   showMenu: true, 
            //   icone: 'mdi-menu-right', 
            //   dscmenu: 'Comprovante recebimento', 
            //   menu: 'RelComprovanteRecebimento'
            // },
            // {
            //   showMenu: true, 
            //   icone: 'mdi-menu-right', 
            //   dscmenu: 'Caixa e contas', 
            //   menu: 'RelCaixaContas'
            // },
            // {
            //   showMenu: true, 
            //   icone: 'mdi-menu-right', 
            //   dscmenu: 'Movimentações entre contas',
            //   menu: 'RelMovimentacoesEntreContas'
            // },
            // {
            //   showMenu: true, 
            //   icone: 'mdi-menu-right', 
            //   dscmenu: 'Colaborador',
            //   menu: 'RelColaborador'
            // }
          ]
        },
      ]
    }
  },
}
</script>

<style>
main {
  display: flex;
  justify-content: space-between;
}

.box {
  flex: 1;
  /* Cresce para preencher o espaço disponível igualmente */
}

.menu_pagina {
  height: 100vh;
}

.corpo_pagina {
  margin-top: 0px;
}

/* Estilos para dispositivos móveis */
@media only screen and (max-width: 1264px) {
  .corpo_pagina {
    margin-top: 40px;
  }
}

.rodape_pagina {
  background-color: aqua;
}
</style>