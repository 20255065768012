<template>
  <div>
    <edita-dados
      :idregistro="idregistro" 
      :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro" 
      :campos_formulario="array_campos_formulario"
      :showProgresso="showProgresso" 
      @saveCompleted="saveCompleted" 
      @close="close"
    >
      <v-text-field
        type="text"
        :label="array_campos_formulario[0].label"
        v-model="array_campos_formulario[0].valor"
        autofocus 
        color="#94896d"
        :rules="descricaoRules"
      />
    </edita-dados>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import EditaDados from '@/components/crud/EditaDados.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  created() {
    this.initialize();
  },
  mixins: [mixin],
  components: {
    EditaDados
  },
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Edição de conta',
      apiAtualizarRegistro: api.contaAtualizar,
      array_campos_formulario: [
        { label: 'Descrição', nome_campo: 'descricao', descricao: '', valor: null, obrigatorio: true }
      ],
      showProgresso: false
    }
  },

  computed: {
    descricaoRules() {
      return [
        v => !!v || "A descrição é obrigatória",
        v => !v || v.length > 3 || 'A descrição deverá possuir ao menos 3 caracteres'
      ];
    },
  },
  methods: {
    async initialize() {
      try {
        this.showProgresso = true;

        let result = await getApi(api.contaConsultar, this.$route.params.id)
        this.array_campos_formulario[0].valor = result.data.descricao;
        this.showProgresso = false;
      } catch (erro) {
        console.error(erro);
        this.showProgresso = false;
      }

    },
    saveCompleted(item) {
      this.$router.push({ name: 'Contas' });
    },
    close() {
      this.$router.push({ name: 'Contas' });
    },
  }
}
</script>

<style scoped></style>