<template>
  <div>
    <edita-dados :idregistro="idregistro" :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro" :campos_formulario="array_campos_formulario"
      :showProgresso="showProgresso" @saveCompleted="saveCompleted" @close="close" @imprimir="imprimirItem">
      <!-- Dados de identificação 1 -->
      <v-row>
        <v-col cols="4">
          <v-text-field dense :label="array_campos_formulario[0].label" type="text" ref="nome"
            v-model="array_campos_formulario[0].valor" autofocus color="#94896d" maxlength="100"
            :rules="obrigatorioRules" />
        </v-col>

        <v-col cols="3">
          <v-text-field :label="array_campos_formulario[13].label" dense type="text"
            v-model="array_campos_formulario[13].valor" v-mask="['###.###.###-##']" color="#94896d"
            :rules="obrigatorioRules" />
        </v-col>

        <v-col cols="3">
          <v-text-field :label="array_campos_formulario[14].label" dense type="text"
            v-model="array_campos_formulario[14].valor" color="#94896d" maxlength="14" />
        </v-col>
        <v-col cols="2">
          <v-switch color="blue" :label="array_campos_formulario[30].label"
            v-model="array_campos_formulario[30].valor" />
        </v-col>
      </v-row>

      <!-- Dados de identificação 2 -->
      <v-row>
        <v-col cols="4">
          <v-select :label="array_campos_formulario[1].label" dense :items="listaSexo" item-text="descricao"
            item-value="sigla" v-model="array_campos_formulario[1].valor" color="#94896d" />
        </v-col>

        <v-col cols="4">
          <v-text-field :label="array_campos_formulario[17].label" dense type="text"
            v-model="array_campos_formulario[17].valor" color="#94896d" maxlength="100" />
        </v-col>
        <v-col cols="4">
          <DialogData titulo="Data de nascimento:" v-model="array_campos_formulario[21].valor" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select :label="array_campos_formulario[3].label" :items="listaEstadoCivil"
            v-model="array_campos_formulario[3].valor" color="#94896d" dense />
        </v-col>

        <v-col cols="4">
          <DialogData titulo="Data de casamento:" v-model="array_campos_formulario[24].valor" />
        </v-col>

        <v-col cols="4">
          <v-text-field :label="array_campos_formulario[4].label" dense type="number" ref="numero_filho"
            v-model="array_campos_formulario[4].valor" color="#94896d">
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider class="mt-4 py-4" />
      <!-- Dados de endereço parte 1 -->
      <v-row>
        <v-col cols="2">

          <v-text-field dense type="text" :label="array_campos_formulario[9].label"
            v-model="array_campos_formulario[9].valor" v-mask="'#####-###'" color="#94896d" />
        </v-col>

        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[5].label"
            v-model="array_campos_formulario[5].valor" maxlength="100" color="#94896d" />
        </v-col>
        <v-col cols="2">
          <v-text-field dense type="text" :label="array_campos_formulario[29].label"
            v-model="array_campos_formulario[29].valor" maxlength="100" color="#94896d" />
        </v-col>
        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[28].label"
            v-model="array_campos_formulario[28].valor" maxlength="100" color="#94896d" />
        </v-col>

      </v-row>

      <!-- Dados de endereço parte 2 -->
      <v-row>
        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[6].label"
            v-model="array_campos_formulario[6].valor" maxlength="100" color="#94896d" />
        </v-col>
        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[7].label"
            v-model="array_campos_formulario[7].valor" maxlength="100" color="#94896d" />
        </v-col>
        <v-col cols="4">
          <v-select :label="array_campos_formulario[8].label" :items="listaUf" item-text="descricao" item-value="sigla"
            v-model="array_campos_formulario[8].valor" color="#94896d" dense />
        </v-col>

      </v-row>

      <!-- Dados de contato -->
      <v-row>
        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[12].label"
            v-model="array_campos_formulario[12].valor" maxlength="120" :rules="emailRules" color="#94896d" />
        </v-col>

        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[10].label"
            v-model="array_campos_formulario[10].valor" v-mask="['(##) ####-####']" color="#94896d" />
        </v-col>

        <v-col cols="4">
          <v-text-field dense type="text" :label="array_campos_formulario[11].label"
            v-model="array_campos_formulario[11].valor" v-mask="['(##) #####-####']" color="#94896d" />
        </v-col>
      </v-row>

      <!-- Dados dos pais e dados profissionais -->

      <v-divider class="mt-4 py-4" />
      <v-row>
        <v-col cols="6">
          <v-text-field dense type="text" :label="array_campos_formulario[19].label"
            v-model="array_campos_formulario[19].valor" maxlength="100" color="#94896d" />
        </v-col>

        <v-col cols="6">
          <v-text-field dense type="text" :label="array_campos_formulario[20].label"
            v-model="array_campos_formulario[20].valor" maxlength="100" color="#94896d" />
        </v-col>
      </v-row>

      <!-- Escolaridade e Profissão -->
      <v-row>
        <v-col cols="6">
          <v-select :items="listaEscolaridade" :label="array_campos_formulario[18].label"
            v-model="array_campos_formulario[18].valor" color="#94896d" dense />
        </v-col>

        <v-col cols="6">
          <v-text-field dense type="text" :label="array_campos_formulario[16].label"
            v-model="array_campos_formulario[16].valor" maxlength="100" color="#94896d" />
        </v-col>
      </v-row>
      <!-- Dados da igreja -->
      <v-row>
        <v-col cols="4">
          <DialogData :titulo="array_campos_formulario[22].label" v-model="array_campos_formulario[22].valor" />
        </v-col>
        <v-col cols="4">
          <DialogData :titulo="array_campos_formulario[23].label" v-model="array_campos_formulario[23].valor" />
        </v-col>
        <v-col cols="4">
          <DialogData :titulo="array_campos_formulario[25].label" v-model="array_campos_formulario[25].valor" />
        </v-col>
      </v-row>

      <!-- Observações -->
      <v-row>
        <v-col cols="12">
          <v-textarea dense type="text" :label="array_campos_formulario[26].label"
            v-model="array_campos_formulario[26].valor" color="#94896d" maxlength="255" />
        </v-col>
      </v-row>
      <v-row
        v-if="array_campos_formulario[27] && array_campos_formulario[27].valor && array_campos_formulario[27].valor.length">
        <v-col cols="12">
          <div>
            Contrato Número: {{ array_campos_formulario[27].valor[0]?.numero_contrato }}
            <v-icon small class="mr-2" @click="abrirContratoPessoa(array_campos_formulario[27].valor[0])">
              mdi-open-in-new
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </edita-dados>
    <edita-pessoa-familia :idpessoa="idregistro" :showProgresso="showProgresso">
    </edita-pessoa-familia>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import EditaDados from '@/components/crud/EditaDados.vue';
import EditaPessoaFamilia from '@/views/pessoa/EditaPessoaFamilia.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';
import DialogData from '@/components/dialogs/DialogData.vue';
import geradorPDF from '@/mixins/geradorPDF';


export default {
  mixins: [mixin, geradorPDF],

  components: {
    EditaDados,
    EditaPessoaFamilia,
    DialogData,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Detalhes cadastro de membro',
      apiAtualizarRegistro: api.pessoaAtualizar,
      apiImprimirRegistro: api.pessoaImprimir,
      array_campos_formulario: [
        {
          label: 'Nome',
          nome_campo: 'nome',
          nome: '',
          valor: null,
          obrigatorio: true,
        }, //0
        {
          label: 'Sexo',
          nome_campo: 'sexo',
          sexo: '',
          valor: null,
          obrigatorio: false,
        }, //1
        {
          label: 'Status',
          nome_campo: 'status',
          status: '',
          valor: null,
          obrigatorio: false,
        }, //2
        {
          label: 'Estado Civil',
          nome_campo: 'estado_civil',
          estado_civil: '',
          valor: null,
          obrigatorio: false,
        }, //3
        {
          label: 'Número de Filhos',
          nome_campo: 'numero_filho',
          numero_filho: '',
          valor: null,
          obrigatorio: false,
        }, //4
        {
          label: 'Endereço',
          nome_campo: 'endereco',
          endereco: '',
          valor: null,
          obrigatorio: false,
        }, //5
        {
          label: 'Bairro',
          nome_campo: 'bairro',
          bairro: '',
          valor: null,
          obrigatorio: false,
        }, //6
        {
          label: 'Cidade',
          nome_campo: 'cidade',
          cidade: '',
          valor: null,
          obrigatorio: false,
        }, //7
        {
          label: 'UF',
          nome_campo: 'uf',
          uf: '',
          valor: null,
          obrigatorio: false,
        }, //8
        {
          label: 'CEP',
          nome_campo: 'cep',
          cep: '',
          valor: null,
          obrigatorio: false,
        }, //9
        {
          label: 'Telefone',
          nome_campo: 'telefone',
          telefone: '',
          valor: null,
          obrigatorio: false,
        }, //10
        {
          label: 'Celular',
          nome_campo: 'celular',
          celular: '',
          valor: null,
          obrigatorio: false,
        }, //11
        {
          label: 'E-mail',
          nome_campo: 'email',
          email: '',
          valor: null,
          obrigatorio: false,
        }, //12
        {
          label: 'CPF',
          nome_campo: 'cpf',
          cpf: '',
          valor: null,
          obrigatorio: false,
        }, //13
        {
          label: 'RG',
          nome_campo: 'rg',
          rg: '',
          valor: null,
          obrigatorio: false,
        }, //14
        {
          label: 'Confessionalidade',
          nome_campo: 'confessionalidade',
          confessionalidade: '',
          valor: null,
          obrigatorio: false,
        }, //15
        {
          label: 'Profissão',
          nome_campo: 'profissao',
          profissao: '',
          valor: null,
          obrigatorio: false,
        }, //16
        {
          label: 'Naturalidade',
          nome_campo: 'naturalidade',
          naturalidade: '',
          valor: null,
          obrigatorio: false,
        }, //17
        {
          label: 'Escolaridade',
          nome_campo: 'escolaridade',
          escolaridade: '',
          valor: null,
          obrigatorio: false,
        }, //18
        {
          label: 'Nome do Pai',
          nome_campo: 'pai',
          pai: '',
          valor: null,
          obrigatorio: false,
        }, //19
        {
          label: 'Nome do Mãe',
          nome_campo: 'mae',
          mae: '',
          valor: null,
          obrigatorio: false,
        }, //20
        {
          label: 'Data Nascimento',
          nome_campo: 'data_nascimento',
          data_nascimento: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          valor: null,
          obrigatorio: false,
        }, //21
        {
          label: 'Data de Batismo',
          nome_campo: 'dt_batismo',
          dt_batismo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          valor: null,
          obrigatorio: false,
        }, //22
        {
          label: 'Data de Confirmação',
          nome_campo: 'dt_confirmacao',
          dt_confirmacao: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          valor: null,
          obrigatorio: false,
        }, //23
        {
          label: 'Data de Casamento',
          nome_campo: 'dt_casamento',
          dt_casamento: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          valor: null,
          obrigatorio: false,
        }, //24
        {
          label: 'Data de Falecimento',
          nome_campo: 'dt_falecimento',
          dt_falecimento: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          valor: null,
          obrigatorio: false,
        }, //25
        {
          label: 'Observação',
          nome_campo: 'observacao',
          observacao: '',
          valor: null,
          obrigatorio: false,
        }, //26
        {
          label: 'Contrato',
          nome_campo: 'contrato',
          valor: null,
          obrigatorio: false,
        }, // 27 
        {
          label: 'Complemento',
          nome_campo: 'complemento',
          valor: null,
          obrigatorio: false,
        }, // 28
        {
          label: 'Número',
          nome_campo: 'numero',
          valor: null,
          obrigatorio: false,
        }, // 29
        {
          label: 'Dependente',
          nome_campo: 'dependente',
          valor: null,
          obrigatorio: false,
        }, // 30
      ],
      showProgresso: false,
      listaSexo: [
        { descricao: '', sigla: '' },
        { descricao: 'Masculino', sigla: 'M' },
        { descricao: 'Feminino', sigla: 'F' },
      ],
      listaEscolaridade: [
        '',
        'Educação básica',
        'Ensino médio',
        'Superior',
        'Pós-Graduação',
      ],
      listaUf: [
        { descricao: 'Acre', sigla: 'AC' },
        { descricao: 'Alagoas', sigla: 'AL' },
        { descricao: 'Amapá', sigla: 'AP' },
        { descricao: 'Amazonas', sigla: 'AM' },
        { descricao: 'Bahia', sigla: 'BA' },
        { descricao: 'Ceará', sigla: 'CE' },
        { descricao: 'Distrito Federal', sigla: 'DF' },
        { descricao: 'Espírito Santo', sigla: 'ES' },
        { descricao: 'Goiás', sigla: 'GO' },
        { descricao: 'Maranhão', sigla: 'MA' },
        { descricao: 'Mato Grosso', sigla: 'MT' },
        { descricao: 'Mato Grosso do Sul', sigla: 'MS' },
        { descricao: 'Minas Gerais', sigla: 'MG' },
        { descricao: 'Pará', sigla: 'PA' },
        { descricao: 'Paraíba', sigla: 'PB' },
        { descricao: 'Paraná', sigla: 'PR' },
        { descricao: 'Pernambuco', sigla: 'PE' },
        { descricao: 'Piauí', sigla: 'PI' },
        { descricao: 'Rio de Janeiro', sigla: 'RJ' },
        { descricao: 'Rio Grande do Norte', sigla: 'RN' },
        { descricao: 'Rio Grande do Sul', sigla: 'RS' },
        { descricao: 'Rondônia', sigla: 'RO' },
        { descricao: 'Roraima', sigla: 'RR' },
        { descricao: 'Santa Catarina', sigla: 'SC' },
        { descricao: 'São Paulo', sigla: 'SP' },
        { descricao: 'Sergipe', sigla: 'SE' },
        { descricao: 'Tocantins', sigla: 'TO' },
      ],
      listaEstadoCivil: [
        '',
        'Solteiro',
        'União Estável',
        'Casado',
        'Separado',
        'Divorciado',
        'Viúvo',
      ],
    };
  },
  computed: {
    emailRules() {
      return this.validaEmail();
    },
    obrigatorioRules() {
      return [
        v => !!v || "Campo obrigatória",
      ];
    },
  },
  methods: {
    async initialize() {
      this.showProgresso = true;
      try {
        let res = await getApi(api.pessoaConsultar, this.$route.params.id);
        this.array_campos_formulario[0].valor = res.data.nome;
        this.array_campos_formulario[1].valor = res.data.sexo;
        this.array_campos_formulario[2].valor = res.data.status;
        this.array_campos_formulario[3].valor = res.data.estado_civil;
        this.array_campos_formulario[4].valor = res.data.numero_filho;
        this.array_campos_formulario[5].valor = res.data.endereco;
        this.array_campos_formulario[6].valor = res.data.bairro;
        this.array_campos_formulario[7].valor = res.data.cidade;
        this.array_campos_formulario[8].valor = res.data.uf;
        this.array_campos_formulario[9].valor = res.data.cep;
        this.array_campos_formulario[10].valor = res.data.telefone;
        this.array_campos_formulario[11].valor = res.data.celular;
        this.array_campos_formulario[12].valor = res.data.email;
        this.array_campos_formulario[13].valor = res.data.cpf;
        this.array_campos_formulario[14].valor = res.data.rg;
        this.array_campos_formulario[15].valor = res.data.confessionalidade;
        this.array_campos_formulario[16].valor = res.data.profissao;
        this.array_campos_formulario[17].valor = res.data.naturalidade;
        this.array_campos_formulario[18].valor = res.data.escolaridade;
        this.array_campos_formulario[19].valor = res.data.pai;
        this.array_campos_formulario[20].valor = res.data.mae;
        this.array_campos_formulario[21].valor = res.data.data_nascimento;
        this.array_campos_formulario[22].valor = res.data.dt_batismo;
        this.array_campos_formulario[23].valor = res.data.dt_confirmacao;
        this.array_campos_formulario[24].valor = res.data.dt_casamento;
        this.array_campos_formulario[25].valor = res.data.dt_falecimento;
        this.array_campos_formulario[26].valor = res.data.observacao;
        this.array_campos_formulario[27].valor = res.data.contrato;
        this.array_campos_formulario[28].valor = res.data.complemento;
        this.array_campos_formulario[29].valor = res.data.numero;
        this.array_campos_formulario[30].valor = res.data.dependente;
        this.showProgresso = false;
      }
      catch (err) {
        setTimeout(() => {
          this.showProgresso = false;
        }, 100);
        console.log(err);
      };
    },

    saveCompleted(item) {
      this.$router.push({ name: 'Pessoas' });
    },
    close() {
      this.$router.push({ name: 'Pessoas' });
    },
    abrirContratoPessoa(item) {
      const routeData = this.$router.resolve({ path: '/contrato/editar/' + item.idcontrato });
      window.open(routeData.href, '_blank');
    },
    async imprimirItem() {
      this.gerarPDF(this.apiImprimirRegistro, this.$route.params.id, 'cadastro_membro');
    },
  },
};
</script>

<style scoped></style>