/**
 * Fetch customizado para enviar parâmetros padrões esperados pelos webservices
 * @param url da requisição
 * @param req parametros da requisicao: method, headers, body, timeout
 */

import axios from 'axios';

export default async function customFetch(url, req, portal = process.env.VUE_APP_URL_API + process.env.VUE_APP_API_VERSION) {

  const urlHomolog = portal + url;

  let method = req.method

  if (!method) {
      method = "GET"
  }

  method = method.toLowerCase();
  let token = window.localStorage.getItem('token');

  let options = {
      method,
      headers: {
        Authorization: "Bearer "+token,
        ...req.headers,
      },
      timeout: req.timeout || 60000
    };

  // inserindo corpo se método != get
  if (!!method && method.toUpperCase() != "get") {
      options["data"] = JSON.stringify({
      ...req.body,
      });
      options["headers"] = {
      ...options["headers"],
      "Content-Type": "application/json"
      }
  }

  let axios_response = await axios(urlHomolog, options).catch((e) => {
    // Devolvendo um erro, caso o mesmo exista
    const erro = {status: '', message: ''};
    erro.message = e.response.data.message;
    erro.status = e.response.status;
    if (erro) {
      throw erro;
    }
    if(e.response.status == 500) {
      if(e.response.data.message !== '' && e.response.data.message !== undefined) {
        throw e.response.data.message;
      }
    }
  });
  return {
      status: axios_response.status,
      statusText: axios_response.statusText,
      message: JSON.parse(axios_response.request.response).message,
      headers: axios_response.headers,
      text: function () {
          return new Promise((resolve) => {
          resolve(axios_response.data);
          })
      },
      json: function () {
          return new Promise((resolve) => {
          resolve(axios_response.data);
          })
      }
  }
}