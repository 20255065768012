<template>
    <v-footer app class="rodape">
      <span class="texto-rodape">{{texto_rodape}}</span>
    </v-footer>
</template>

<script>
export default {
  props: {
      texto_rodape: {
          type: String,
      },
  }    
}
</script>

<style scoped>
  .rodape {
    display: flex; 
    justify-content: center; 
    padding: 2px; 
    background-color: #f5f4ec;
  }

  .texto-rodape {
    color: #94896d;
  }
</style>