<template>
  <div>
    <visualiza-dados 
      :cabecalhos_colunas="cabecalhos_colunas"
      :id_registro="id_registro"
      :titulo_listagem="titulo_listagem"
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="coluna_ordenar"
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem" 
      :mensagem_exclusao="mensagem_exclusao"
    />
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import { api } from '@/network/Api';

export default {
  components: {
    VisualizaDados
  },
  data() {
    return {
      titulo_listagem: 'Formas de pagamento',
      cabecalhos_colunas: [
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false
        }
      ],
      coluna_ordenar: 'descricao',
      apiConsultarRegistros: api.formaPagamentoListarTodas,
      apiExcluirRegistro: api.formaPagamentoExcluir,
      mensagem_exclusao: 'Tem certeza que deseja excluir esta forma de pagamento?',
      id_registro: 'idformapagamento',
    }
  },

  methods: {
    cadastrarItem() {
      this.$router.push({ name: 'FormaPagamentoInsert' });
    },
    editarItem(params) {
      this.$router.push({ name: 'FormaPagamentoEdit', params: { id: params.id } });
    },
  },
};
</script>

<style scoped></style>