<template>
  <div>
    <v-row class="titulo__historico" justify="space-between">
      <v-col cols="6">
        Histórico de Renovação de Contrato
      </v-col>
      <v-col @click="gerarRenovacaoParcela" offset="3" cols="3" style="text-align: right;">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span style="font-size: 14px;padding-right: 12px;">Renovar</span>
            <v-icon color="white" v-bind="attrs" v-on="on">mdi-shape-square-rounded-plus</v-icon>
          </template>
          <span>Renovar Contrato</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table :headers="titulos" :items="registros" :items-per-page="20" class="elevation-1">
      <template v-slot:[`item.dt_validade_inicio`]="{ item }">
        {{ formataData(item.dt_validade_inicio) }}
      </template>
      <template v-slot:[`item.dt_validade_fim`]="{ item }">
        {{ formataData(item.dt_validade_fim) }}
      </template>
      <template v-slot:[`item.dt_assinatura`]="{ item }">
        {{ formataData(item.dt_assinatura) }}
      </template>
      <template v-slot:[`item.valor_total`]="{ item }">
        {{ formataDinheiro(item.valor_total) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="abrirPagamento(item)">
          mdi-open-in-new
        </v-icon>
      </template>
    </v-data-table>
    <contrato-historico-renovacao class="mb-2" :dialogoVisivel="dialogoRenovacao" :idcontrato="idcontrato"
      @fecharRenovacao="fecharRenovacao" />
  </div>
</template>

<script>
import formatacaoMixin from '@/mixins/formatacao';
import ContratoHistoricoRenovacao from '@/views/contrato/historico/ContratoHistoricoRenovacao.vue'

export default {
  components: { ContratoHistoricoRenovacao },
  mixins: [formatacaoMixin],
  props: {
    registros: {
      type: Array
    },
    idcontrato: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      dialogoRenovacao: false,
      titulos: [
        {
          text: 'Ordem',
          align: 'start',
          sortable: true,
          value: 'ordem',
        },
        {
          text: 'Data Assinatura',
          align: 'start',
          sortable: true,
          value: 'dt_assinatura',
        },
        {
          text: 'Validade Inicio',
          align: 'start',
          sortable: true,
          value: 'dt_validade_inicio',
        },
        {
          text: 'Validade Fim',
          align: 'start',
          sortable: true,
          value: 'dt_validade_fim',
        },
        {
          text: 'Valor',
          align: 'start',
          sortable: true,
          value: 'valor_total',
        },
        {
          text: 'Parcelas',
          align: 'start',
          sortable: true,
          value: 'qt_parcela',
        },
        {
          text: 'Observação',
          align: 'start',
          sortable: true,
          value: 'observacao',
        },
        {
          text: 'Ações',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
    }
  },
  methods: {
    abrirPagamento(item) {
      const routeData = this.$router.resolve({ path: '/contrato/historico/' + item.idcontratohistorico });
      window.open(routeData.href, '_blank');
    },
    gerarRenovacaoParcela() {
      this.dialogoRenovacao = true;
    },
    fecharRenovacao() {
      this.dialogoRenovacao = false;
      window.location.reload();
    }
  },
};
</script>

<style scoped>
.titulo__historico {
  font-size: 1.4rem;
  padding: 4px;
  margin: 0;
  background-color: #928a6dd9;
  color: #FFFFFF;
}
</style>
