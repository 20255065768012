<template>
    <v-dialog
        v-model="showMensagem"
        width="500"
    >
        <v-card>
            <v-card-title style="background-color: #94896d; color:#FFFFFF;" class="text-h6">
                {{ tituloDialogMsgAlerta }}
            </v-card-title>

            <v-card-text style="margin-top: 20px;">
                {{ mensagemAlerta }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color="#990000"
                    dark
                    @click="showMensagem = false"
                >
                    <span style="color: #ffffff">Fechar</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        props: {
            tituloDialogMsgAlerta: {
                type: String,
            },
            mensagemAlerta: {
                type: String,
            },
            showMensagem: {
                type: Boolean,
                default: false
            },
        },
    }
</script>