<template>
  <div>
    <insere-dados
      :titulo_formulario="titulo_formulario" 
      :apiInserirRegistro="apiInserirRegistro"
      :campos_formulario="array_campos_formulario"
      @saveCompleted="saveCompleted"
      @close="close"
    >
      <v-text-field 
        type="text" 
        :label="array_campos_formulario[0].label"
        v-model="array_campos_formulario[0].valor"
        autofocus color="#94896d"
        :rules="descricaoRules"
      />
    </insere-dados>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import InsereDados from '@/components/crud/InsereDados.vue';
import { api } from '@/network/Api';

export default {
  mixins: [mixin],
  components: {
    InsereDados
  },
  data() {
    return {
      titulo_formulario: 'Cadastro de forma de pagamento',
      apiInserirRegistro: api.formaPagamentoCriar,
      array_campos_formulario: [
        { label: 'Descrição', nome_campo: 'descricao', descricao: '', obrigatorio: true }
      ],
    }
  },

  computed: {
    descricaoRules() {
      return [
        v => !!v || "A descrição é obrigatória",
        v => !v || v.length >= 3 || 'A descrição deverá possuir ao menos 3 caracteres'
      ];
    },
  },

  methods: {
    saveCompleted(item) {
      this.$router.push({ name: 'FormasPagamento' });
    },
    close() {
      this.$router.push({ name: 'FormasPagamento' });
    }
  }
}
</script>

<style scoped></style>