<template>
  <div>
    <v-menu ref="data" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
      max-width="290px" min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :disabled="disabled" dense :value="computedDateFormattedMomentjs" :label="titulo" persistent-hint
          clearable readonly prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" @click:clear="date = null" />
      </template>
      <v-date-picker v-model="date" no-title @input="menu = false" />
    </v-menu>
  </div>
</template>

<script>

import moment from 'moment';


export default {
  data() {
    return {
      date: this.value,
      menu: false,
      self: this
    }
  },

  props: {
    titulo: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format('DD/MM/YYYY') : ''
    },
  },
  watch: {
    value(newValue) {
      this.date = newValue;
      this.$emit('input', newValue ? moment(newValue).format('YYYY-MM-DD') : '');
    },
    date(newValue) {
      this.$emit('input', newValue ? moment(newValue).format('YYYY-MM-DD') : '');
    }
  }
}
</script>