import router from '@/router';
import axios from 'axios';

const baseURL = process.env.VUE_APP_URL_API;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      if (router.currentRoute.path != '/login') {
        router.push({ name: 'Login' });
        return;
      }
    }
    if (error.response.status == 403) {
      alert("Sem Permissão")
    }
    return Promise.reject(error);
});

export default axiosInstance;





