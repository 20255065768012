<template>
  <div>
    <v-card v-show="!showProgresso" max-width="1024" class="mx-auto my-12" style="background-color: #f8f9fa">
      <!-- Título de identificação da tela -->
      <v-card-title style="padding: 0px; margin-bottom: 45px;">
        <v-container style="padding-top: 0px; padding-bottom: 0px;">
          <v-row>
            <v-col cols="12">
              <h5 style="color: #94896d;">{{ titulo_listagem }}</h5>
              <v-divider />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-title style="margin-top: -20px; margin-bottom: 25px; padding: 0px;">
        <v-container style="padding-top: 0px; padding-bottom: 0px;">
          <v-row style="display: flex; justify-content: center;">
            <v-col cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
              <v-select
                label="Ano"
                :items="listaAnos"
                item-text="ano"
                item-value="ano"
                v-model="anoSelecionado"
                color="#94896d" />
            </v-col>

            <v-col cols="8" xs="8" sm="8" md="8" lg="6" xl="6">
              <!-- Campo para pesquisar -->
              <v-text-field 
                dense 
                v-model="search" 
                prepend-inner-icon="mdi-magnify" 
                label="Digite aqui para pesquisar"
                clearable 
                solo-inverted 
                hide-details 
                color="#FFFFFF" 
                style="background-color: #f5f4ec"
              />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end;">
              <!-- Botão para chamar o formulário de cadastro -->
              <v-btn color="#990000" dark @click="cadastrar" v-show="mostra_botao_cadastrar">
                <span style="color: #ffffff">Cadastrar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <!-- Tabela para exibir os registros cadastrados -->
      <v-data-table 
        :headers="cabecalhos_colunas"
        :items="registrosFiltrados" 
        :search="search" 
        :sort-by="coluna_ordenar"
        class="elevation-1" 
        style="background-color: #f8f9fa"
      >
        <!-- Define colunas que necessitam de alguma formatação -->
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="column in cabecalhos_colunas" :key="column.value">
              <span v-if="column.value != 'actions'">
                {{ formataColuna(item, column) }}
              </span>

              <!-- Botões para manipular os registros da listagem(Edição e Exclusão) -->
              <span v-if="column.value == 'actions'">
                <!-- Botão que chama o formulário de edição -->
                <router-link v-if="pode_editar"
                  :to="{ name: nome_rota_formulario_edicao, params: { id: item[id_registro] } }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" color="#344767" v-bind="attrs" v-on="on">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </router-link>

                <!-- Botão para excluir um registro da listagem -->
                <v-tooltip v-if="pode_excluir" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="deleteItem(item)" color="#344767" v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </span>
            </td>
          </tr>
        </template>

        <!-- Caixa de diálogo para confirmação da exclusão -->
        <template v-slot:[`top`]>
          <v-dialog v-model="dialogDelete" max-width="555px">
            <v-card>
              <v-card-title class="text-h6">{{ mensagem_exclusao }}</v-card-title>

              <!-- Botões para cancelamento ou confirmação da exclusão -->
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="#f5f4ec" dark @click="dialogDelete = false">
                  <span style="color:#990000">Não</span>
                </v-btn>

                <v-btn color="#990000" dark @click="deleteItemConfirm">
                  <span style="color:#FFFFFF">Sim</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />

    <!-- Caixa de diálogo para exibir mensagens -->
    <dialog-msg-alerta tituloDialogMsgAlerta="Atenção!" :mensagemAlerta="mensagemAlerta"
      :showMensagem="dialogMensagemALerta">
    </dialog-msg-alerta>
  </div>
</template>

<script>
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue'
import TelaCarregando from '@/components/TelaCarregando.vue';
import { excluirApi, getApi } from '@/network/ApiService';

export default {
  components: {
    DialogMsgAlerta,
    TelaCarregando
  },
  props: {
    cabecalhos_colunas: {
      type: Array,
      required: true
    },
    coluna_ordenar: {
      type: String,
      required: false
    },
    id_registro: {
      type: String,
      required: true
    },
    titulo_listagem: {
      type: String,
      required: false
    },
    apiConsultarRegistros: {
      type: String,
      required: true
    },
    apiExcluirRegistro: {
      type: String,
      required: false
    },
    nome_rota_formulario_insercao: {
      type: String,
      required: false
    },
    nome_rota_formulario_edicao: {
      type: String,
      required: false
    },
    coluna_ordenar: {
      type: String,
      required: false
    },
    mensagem_exclusao: {
      type: String,
      required: false
    },
    formataColuna: {
      type: Function,
      default: (item, column) => item[column.value],
    },
    pode_editar: {
      type: Boolean,
      default: true
    },
    pode_excluir: {
      type: Boolean,
      default: true
    },
    mostra_botao_cadastrar: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.initialize();
  },

  data() {
    return {
      dialogMensagemALerta: false,
      mensagemAlerta: '',
      search: '',
      dialog: false,
      dialogDelete: false,
      registros: [],
      primarykey: -1,
      showProgresso: false,
      total_registros: 0,
      anoSelecionado: '',
      listaAnos: []
    }
  },

  methods: {
    initialize() {
      this.configuraFiltroAno();
      this.showProgresso = true;
      getApi(this.apiConsultarRegistros)
        .then(res => {
          this.registros = res.data;
          this.total_registros = this.registros.length;
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        })
        .catch(err => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
          console.log(err);
        });
    },
    configuraFiltroAno() {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      this.anoSelecionado = anoAtual;
      
      var anos = [];

      // Adicionar -10 / atual / +5 anos
      for (var i = anoAtual - 10; i < anoAtual; i++) {
          anos.push(i);
      }
      anos.push(anoAtual);
      for (var j = anoAtual + 1; j <= anoAtual + 5; j++) {
          anos.push(j);
      }
      
      this.listaAnos = anos;
    },
    deleteItem(item) {
      // Coleta o registro seleciondo para edição
      this.editedItem = Object.assign({}, item)
      // Coleta o indice do resgitro selecionado pera edição
      this.primarykey = this.editedItem[this.id_registro];
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      // Exclui registro no banco de dados
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch(err => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta = 'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.primarykey = -1
      this.clearForm();
      this.initialize();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.initialize();
      this.mostra_botao_cadastrar = true;
    },
    cadastrar() {
      this.$router.push({ name: this.nome_rota_formulario_insercao });
      // this.$router.push({name: 'UsuarioInsert'});
      // this.$router.push({name: 'UsuarioEdit'});
    }
  },
  computed: {
    registrosFiltrados() {
      return this.registros.filter(item => { 
        return item.ano == this.anoSelecionado;
      });
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.centralizado {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Isso é opcional, dependendo do seu layout */
}

.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>