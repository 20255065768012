<template>
  <div>
    <edita-dados-v2 :idregistro="idregistro" :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro" :campos_formulario="dados" :showProgresso="showProgresso"
      @saveCompleted="saveCompleted" @close="close" @imprimir="imprimirItem">
      <v-row>
        <v-col cols="4">
          <v-text-field type="text" label="Número contrato" v-model="dados.numero_contrato" autofocus color="#94896d"
            filled disabled />
        </v-col>
        <v-col cols="4" offset="4">
          <v-switch label="Utiliza cobrador" v-model="dados.utiliza_cobrador" filled color="blue" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-autocomplete label="Titular do contrato" v-model="dados.idpessoa_titular" :items="listPessoas"
            item-value="idpessoa" item-text="nome" color="#94896d" :rules="titularRules" autofocus filled clearable />
        </v-col>
        <v-col cols="4">
          <v-autocomplete label="Faixa do contrato" v-model="dados.idfaixacontrato" :items="listFaixas"
            item-value="idfaixacontrato" item-text="descricao" color="#94896d" :rules="faixaRules" filled autofocus
            clearable />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select :items="listaStatus" item-text="descricao" item-value="valor" label="Status" v-model="dados.status"
            color="#94896d" filled />
        </v-col>
        <v-col cols="4">
          <v-select :items="listaContas" item-value="idconta" item-text="descricao" label="Conta padrão"
            v-model="dados.idconta" filled color="#94896d" />

        </v-col>
        <v-col cols="4">
          <v-select :items="listaFormasPagamento" item-value="idformapagamento" item-text="descricao"
            label="Forma Pagamento padrão" v-model="dados.idformapagamento_padrao" filled color="#94896d" />
        </v-col>
      </v-row>
      <v-row>

        <v-col cols="6">
          <CampoData titulo="Data de assinatura" v-model="dados.data_assinatura" :rules="dataAssinaturaRules" />
        </v-col>
        <v-col cols="6">
          <CampoData titulo="Data de desligamento (opcional)" v-model="dados.data_desligamento" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ContratoHistoricoView :registros="historico" :idcontrato="idcontrato" />
        </v-col>
      </v-row>
    </edita-dados-v2>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import EditaDadosV2 from '@/components/crud/EditaDadosV2';
import ContratoHistoricoView from '@/views/contrato/historico/ContratoHistoricoView';
import { api } from '@/network/Api';
import geradorPDF from '@/mixins/geradorPDF';
import { getApi } from '@/network/ApiService';

export default {
  created() {
    this.initialize();
  },
  mixins: [mixin, geradorPDF],
  components: {
    EditaDadosV2,
    ContratoHistoricoView
  },
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Edição de contrato',
      apiAtualizarRegistro: api.contratoAtualizar,
      apiImprimirRegistro: api.contratoImprimir,
      historico: [],
      idcontrato: null,

      dados: {
        numero_contrato: '',
        idpessoa_titular: '',
        idfaixacontrato: '',
        idconta: '',
        idformapagamento_padrao: '',
        utiliza_cobrador: '',
        status: '',
        data_assinatura: '',
        data_desligamento: '',
      },
      showProgresso: false,
      listaContas: [],
      listaFormasPagamento: [],
      listFaixas: [],
      listPessoas: [],
      listaStatus: [
        { valor: 1, descricao: 'Ativo' },
        { valor: 2, descricao: 'Inativo' },
        { valor: 3, descricao: 'Desligado' },
      ],
    };
  },

  computed: {
    numeroContratoRules() {
      return [
        v => !!v || "O número do contrato é obrigatório",
      ];
    },
    faixaRules() {
      return [
        v => !!v || "Selecione a faixa do contrato",
      ];
    },
    titularRules() {
      return [
        v => !!v || "Selecione o titular do contrato",
      ];
    },
    dataAssinaturaRules() {
      return [
        v => !!v || "Informe a data de assinatura do contrato",
      ];
    },
  },

  methods: {
    async initialize() {
      this.showProgresso = true;

      getApi(api.contaListarTodas)
        .then((res) => {
          this.listaContas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.formaPagamentoListarTodas)
        .then((res) => {
          this.listaFormasPagamento = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.faixaContratoListarTodas)
        .then((res) => {
          this.listFaixas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.pessoaListarTodas)
        .then((res) => {
          this.listPessoas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.contratoConsultar, this.$route.params.id)
        .then((res) => {
          this.dados.numero_contrato = res.data.numero_contrato;
          this.dados.idpessoa_titular = res.data.idpessoa_titular;
          this.dados.idfaixacontrato = res.data.idfaixacontrato;
          this.dados.idconta = res.data.idconta;
          this.dados.idformapagamento_padrao = res.data.idformapagamento_padrao;
          this.dados.utiliza_cobrador = res.data.utiliza_cobrador;
          this.dados.status = res.data.status;
          this.dados.data_assinatura = res.data.data_assinatura;
          this.dados.data_desligamento = res.data.data_desligamento;
          this.historico = res.data.historico;
          this.idcontrato = res.data.idcontrato;

          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        })
        .catch((err) => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
          console.log(err);
        });
    },
    saveCompleted(item) {
      this.$router.push({ name: 'Contratos' });
    },
    close() {
      this.$router.push({ name: 'Contratos' });
    },
    async imprimirItem() {
      this.gerarPDF(this.apiImprimirRegistro, this.$route.params.id, 'contrato');
    },
  },
};
</script>

<style scoped></style>
