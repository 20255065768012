<template>
  <v-dialog ref="modal" v-model="modal_visivel" persistent width="290px">

    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="modelValueFormatado" :label="titulo" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" locale="pt-br" dense />
    </template>

    <v-date-picker v-model="currentDate" scrollable locale="pt-br" color="#94896d" header-color="#94896d">
      <v-spacer />

      <v-btn text color="primary" @click="cancelar">
        Cancel
      </v-btn>

      <v-btn text color="primary" @click="salvar">
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value:
    {
      type: String,
      default: ''
    },
    titulo:
    {
      type: String,
      default: 'Data'
    }
  },
  data() {
    return {
      modal_visivel: false,
      modelValueFormatado: null,//  new Date().toLocaleDateString('pt-BR'),
      currentDate: null,// new Date().toLocaleDateString('pt-BR'),
      data_original: null
    }
  },
  watch: {
    value(valor, old) {
      if (this.data_original == null) {
        this.data_original = old;
      }
      this.currentDate = valor;
    },

    currentDate(valor) {
      if (valor != null) {
        this.modelValueFormatado = this.formataDataPtBr(valor);
      }
    }
  },
  methods: {
    formataDataPtBr(valor) {
      const partesDaData = valor.split('-');
      // Obtém ano, mês e dia
      const ano = partesDaData[0];
      const mes = partesDaData[1];
      const dia = partesDaData[2];

      // Formata a data como "05/03/1991" (dia/mês/ano)
      return `${dia}/${mes}/${ano}`;
    },
    salvar() {
      this.$emit('input', this.currentDate);
      this.modal_visivel = false;
    },
    cancelar() {
      this.modal_visivel = false;
      this.$emit('input', this.data_original);

    }
  },
  created() {
    this.currentDate = this.value;
    if (this.data_original == null) {
      this.data_original = this.value;;
    }
  }
}
</script>