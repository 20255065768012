<template>
  <div>
    <v-card v-show="!showProgresso" max-width="1500" class="mx-auto my-12" style="background-color: #f8f9fa">
      <v-card-title style="padding: 0px; margin-bottom: 45px">
        <v-row>
          <v-col offset="1" cols="10">
            <h5 style="color: #94896d">{{ tituloRelatorio }}</h5>
            <v-divider />
          </v-col>
          <v-col cols="1">
            <slot name="acoesformulario">
            </slot>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-title style="margin-top: 12px; margin-bottom: 25px; padding: 0px">
        <v-container style="padding-top: 0px; padding-bottom: 0px">
          <slot name="informacoes">
          </slot>
          <v-row>
            <v-col cols="10">
              <slot name="filtros"></slot>
            </v-col>
            <v-col cols="2">
              <slot name="filtros-buscar"></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-row style="padding: 0 2rem;">
        <v-col cols="12">
          <slot name="dados"></slot>
        </v-col>
      </v-row>

    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />

    <!-- Caixa de diálogo para exibir mensagens -->
    <dialog-msg-alerta tituloDialogMsgAlerta="Atenção!" :mensagemAlerta="mensagemAlerta"
      :showMensagem="dialogMensagemAlerta">
    </dialog-msg-alerta>
  </div>
</template>
  
<script>
  import TelaCarregando from '@/components/TelaCarregando.vue'; 

  import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
  export default {
    components: {
      DialogMsgAlerta,
      TelaCarregando
    },
    
    props: {
      tituloRelatorio: {
        type: String,
        default: "Relatório",
      },
      showProgresso: {
        default: false
      }
    },
    created() {
      this.initialize();
    },
    
    data() {
      return {
        dialogMensagemAlerta: false,
        mensagemAlerta: '',
      };
    },
    methods: {
      initialize(){
        setTimeout(()=>{ }, 1000);
      }
    },
  };
</script>
