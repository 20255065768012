<template>
  <div>
    <visualiza-dados :cabecalhos_colunas="cabecalhos_colunas" :id_registro="id_registro"
      :titulo_listagem="titulo_listagem" :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro" :coluna_ordenar="coluna_ordenar" @cadastrarItem="cadastrarItem"
      @editarItem="editarItem" @imprimirItem="imprimirItem" :mensagem_exclusao="mensagem_exclusao"
      :pode_imprimir="true" />
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';

import { api } from '@/network/Api';
import geradorPDF from '@/mixins/geradorPDF';

export default {
  components: {
    VisualizaDados,
  },
  mixins: [geradorPDF],
  data() {
    return {
      titulo_listagem: 'Pessoas',
      cabecalhos_colunas: [
        {
          text: 'Nome',
          value: 'nome',
          align: 'start',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Endereço',
          value: 'endereco',
          align: 'start',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Celular',
          value: 'celular',
          align: 'start',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
      ],
      coluna_ordenar: 'nome',
      apiConsultarRegistros: api.pessoaListarTodas,
      apiExcluirRegistro: api.pessoaExcluir,
      apiImprimirRegistro: api.pessoaImprimir,
      mensagem_exclusao: 'Tem certeza que deseja excluir esta pessoa?',
      id_registro: 'idpessoa',
    }
  },
  methods: {

    cadastrarItem() {
      this.$router.push({ name: 'PessoaInsert' });
    },
    editarItem(params) {
      this.$router.push({ name: 'PessoaEdit', params: { id: params.id } });
    },
    async imprimirItem(params) {
      console.log("Iniciando download!");
      await this.gerarPDF(this.apiImprimirRegistro, params.id, 'cadastro_pessoa_'+params.dados.nome)
      console.log("Fim download!");
    }
  },
};
</script>

<style scoped></style>
