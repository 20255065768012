<template>
  <div>
    <insere-dados
      :titulo_formulario="titulo_formulario"
      :apiInserirRegistro="apiInserirRegistro"
      :campos_formulario="array_campos_formulario"
      @saveCompleted="saveCompleted"
      @close="close">
      <p style="margin-bottom: -20px; color: #94896d">
        * {{ array_campos_formulario[0].label }}:
      </p>

      <v-text-field
        type="text"
        ref="descricao"
        v-model="array_campos_formulario[0].valor"
        autofocus
        color="#94896d">
      </v-text-field>
    </insere-dados>
  </div>
</template>

<script>
  import mixin from '@/store/mixin.js';
  import InsereDados from '@/components/crud/InsereDados.vue';
  import {api} from '@/network/Api';
  
  export default {
    mixins: [mixin],
    components: {
      InsereDados,
    },
    data() {
      return {
        titulo_formulario: 'Cadastro de perfil',
        apiInserirRegistro: api.perfilCriar,
        array_campos_formulario: [
          {
            label: 'Descrição',
            nome_campo: 'descricao',
            descricao: '',
            obrigatorio: true,
          },
        ],
      };
    },
    methods: {
      saveCompleted(item) {
        this.$router.push({name: 'Perfis'});
      },
      close() {
        this.$router.push({name: 'Perfis'});
      }
    },
  };
</script>

<style scoped></style>
