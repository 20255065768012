<template>
  <div>
    <insere-dados 
      :titulo_formulario="titulo_formulario"
      :apiInserirRegistro="apiInserirRegistro"
      :campos_formulario="array_campos_formulario"
      @saveCompleted="saveCompleted" 
      @close="close"
    >
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            label="Titular do contrato" 
            v-model="array_campos_formulario[0].valor"
            :items="listPessoas"
            item-value="idpessoa"
            item-text="nome"
            color="#94896d"
            :rules="titularRules"
            autofocus
            clearable
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            label="Faixa do contrato"
            v-model="array_campos_formulario[1].valor"
            :items="listFaixas"
            item-value="idfaixacontrato" 
            item-text="descricao"
            color="#94896d"
            :rules="faixaRules"
            autofocus
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select 
            label="Conta padrão"
            v-model="array_campos_formulario[3].valor"
            :items="listaContas"
            item-value="idconta"
            item-text="descricao"
            color="#94896d"
            autofocus
            clearable
          />
        </v-col>
        <v-col cols="4">
          <v-select
            label="Forma de pagamento padrão"
            v-model="array_campos_formulario[4].valor"
            :items="listaFormasPagamento"
            item-value="idformapagamento"
            item-text="descricao"
            color="#94896d"
            autofocus
            clearable
          />
        </v-col>
        <v-col cols="4">
          <v-switch label="Utiliza cobrador" v-model="array_campos_formulario[5].valor" color="blue" />
        </v-col>
      </v-row>
      <v-row>
         <v-col cols="4">
          <v-select 
            label="Status"
            v-model="array_campos_formulario[2].valor"
            :items="listaStatus" 
            item-text="descricao"
            item-value="valor"
            color="#94896d"
            :rules="statusRules"
            dense
          />
        </v-col>
        <v-col cols="4">
          <DialogData 
            titulo="Data de assinatura"
            v-model="array_campos_formulario[6].valor"
            :rules="dataAssinaturaRules"
          />
        </v-col>
        <v-col cols="4">
          <DialogData 
            titulo="Data de desligamento (opcional)"
            v-model="array_campos_formulario[7].valor" 
          />
        </v-col>
      </v-row>
    </insere-dados>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import InsereDados from '@/components/crud/InsereDados.vue';
import { api } from '@/network/Api';
import DialogData from '@/components/dialogs/DialogData.vue';
import { getApi } from '@/network/ApiService';

export default {
  mixins: [mixin],
  components: {
    InsereDados,
    DialogData,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      titulo_formulario: 'Novo contrato',
      apiInserirRegistro: api.contratoCriar,
      array_campos_formulario: [
      {
          valor: '',
          nome_campo: 'idpessoa_titular',
          obrigatorio: true,
        },
        {
          valor: '',
          nome_campo: 'idfaixacontrato',
          obrigatorio: false,
        },
        {
          valor: 1,
          nome_campo: 'status',
          obrigatorio: true,
        },
        {
          valor: '',
          nome_campo: 'idconta',
          obrigatorio: true,
        },
        {
          valor: '',
          nome_campo: 'idformapagamento_padrao',
          obrigatorio: true,
        },        
        {
          valor: false,
          nome_campo: 'utiliza_cobrador',
          obrigatorio: true,
        },
        {
          valor: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          nome_campo: 'data_assinatura',
          obrigatorio: true,
        },
        {
          valor: null,
          nome_campo: 'data_desligamento',
          obrigatorio: false,
        },
      ],
      showProgresso: false,
      listaContas: [],
      listaFormasPagamento: [],
      listFaixas: [],
      listPessoas: [],
      listaStatus: [
        { valor: 1, descricao: 'Ativo' },
        { valor: 2, descricao: 'Inativo' },
        { valor: 3, descricao: 'Desligado' },
      ],
    };
  },

  computed: {    
    faixaRules() {
      return [
        v => !!v || "Selecione a faixa do contrato",
      ];
    },
    statusRules() {
      return [
        v => !!v || "Informe o status do contrato",
      ];
    },
    titularRules() {
      return [
        v => !!v || "Selecione o titular do contrato",
      ];
    },
    dataAssinaturaRules() {
      return [
        v => !!v || "Informe a data de assinatura do contrato",
      ];
    },
    
  },
  methods: {
    initialize() {
      this.showProgresso = true;

      getApi(api.contaListarTodas)
        .then((res) => {
          this.listaContas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.formaPagamentoListarTodas)
        .then((res) => {
          this.listaFormasPagamento = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.faixaContratoListarTodas)
        .then((res) => {
          this.listFaixas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.pessoaListarTodas)
        .then((res) => {
          this.listPessoas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveCompleted(item) {
      this.$router.push({ name: 'Contratos' });
    },
    close() {
      this.$router.push({ name: 'Contratos' });
    },
  },
};
</script>

<style scoped></style>
