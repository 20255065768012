<template>
  <div>
    <relatorio-view :showProgresso="showProgresso">
      <template v-slot:informacoes>
        <v-card class="mx-4" outlined>
          <v-list-item-content style="max-width: 100%;" class="pa-6">
            <v-row>
              <v-col cols="3" class="text-overline py-0">
                <span style="font-weight: bold">Número Contrato: </span>
                {{ contrato.numero_contrato }}
              </v-col>
              <v-col cols="6" class="text-overline  py-0">
                <span style="font-weight: bold"> Nome do contrato: </span>
                {{ contrato.nome_contrato }} ({{ idContrato }})
              </v-col>
              <v-col cols="3" class="text-overline  py-0">
                <span style="font-weight: bold">Contrato Histórico: </span>
                {{ idContratoHistorico }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-overline py-0">
                <span style="font-weight: bold">Assinatura: </span>
                {{ formataData(dtAssinatura) }}
              </v-col>
              <v-col cols="6" class="text-overline py-0">
                <span style="font-weight: bold">Vigência do contrato: </span>
                {{ formataData(dtValidadeInicio) }} até {{ formataData(dtValidadeFim) }}
              </v-col>
              <v-col cols="3" class="text-overline py-0">
                <span style="font-weight: bold">Faixa: </span>
                {{ contrato.idfaixacontrato }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-overline py-0">
                <span style="font-weight: bold">Total de Parcelas: </span>
                {{ qtParcela }}
              </v-col>
              <v-col cols="6" class="text-overline py-0">
                <span style="font-weight: bold">Valor Total: </span>
                {{ formataDinheiro(valorTotal) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-overline py-0">
                <span style="font-weight: bold">Observação:</span>
                {{ observacao }}
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-card>
      </template>

      <template v-slot:dados>
        <v-row>
          <v-col cols="10" offset="1">
            <v-data-table fixed-header :headers="headers" :items="pagamentos" :search="search" :sort-by="coluna_ordenar"
              item-key="idpagamento" class="elevation-1" style="background-color: #f8f9fa" height="65vh">

              <template v-slot:top>
                <v-card class="d-flex flex-row-reverse">
                  <div v-if="edicaoPagamentoLote" class="pa-2">
                    <v-btn elevation="2" color="primary" @click="salvarEdicaoLote()">
                      Salvar lote
                    </v-btn>
                  </div>
                  <div class="pa-2" outlined tile>
                    <template v-if="pagamentoLote">
                      <v-btn elevation="auto" color="#d2d6dd" @click="cancelarPagamentoLote()">
                        Cancelar
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn elevation="auto" color="#3dcbff" @click="pagamentoLote = !pagamentoLote">
                        Pagamento em lote
                      </v-btn>
                    </template>

                  </div>
                </v-card>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.parcela`]="{ item }">
                {{ item.numero_parcelas }} / {{ item.parcela_final }}
              </template>

              <template v-slot:[`item.valor_restante`]="{ item }">
                <v-chip :color="getColorValor(item.valor_restante)" dark>
                  {{ formataDinheiro(item.valor_restante) }}
                </v-chip>
              </template>

              <template v-slot:[`item.valor_original`]="{ item }">
                {{ formataDinheiro(item.valor_original) }}
              </template>
              <template v-slot:[`item.valor_efetivo`]="{ item }">
                {{ formataDinheiro(item.valor_efetivo) }}
              </template>

              <!-- <template v-slot:[`item.data_movimentacao`]="{ item }">
              {{ formataData(item.data_movimentacao) }}
            </template> -->
              <template v-slot:[`item.data_vencimento`]="{ item }">
                {{ formataData(item.data_vencimento) }}
              </template> <template v-slot:[`item.data_pagamento`]="{ item }">
                {{ formataData(item.data_pagamento) }}
              </template>
              <template v-slot:[`item.data_programado`]="{ item }">
                {{ formataData(item.data_programado) }}
              </template>
              <template v-slot:[`item.tipo`]="{ item }">
                {{ formataTipo(item.tipo) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="pagamentoLote">
                  <v-switch dense v-model="item.selecionado" :disabled="item.status_pagamento == 'CP'" />
                </template>
                <template v-else>
                  <v-icon small @click="abrirPagamento(item)" class="mr-2" color="#344767">
                    mdi-currency-usd
                  </v-icon>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </relatorio-view>


    <v-dialog v-model="dialogoEditarPagamento" persistent max-width="1024">
      <v-card>
        <v-card-title class="text-h5">
          Pagamento
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field label="Documento" :value="editarPagamento.documento" disabled />
            </v-col>
            <v-col cols="3">
              <v-text-field label="Tipo" :value="formataTipo(editarPagamento.tipo)" disabled />
            </v-col>
            <v-col cols="3">
              <v-text-field label="Valor Original" :value="formataDinheiro(editarPagamento.valor_original)" disabled />
            </v-col>
            <v-col cols="3">
              <v-text-field label="Data Vencimento" :value="formataData(editarPagamento.data_vencimento)" disabled />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-select v-model="editarPagamento.idcaixa" :items="caixas" item-text="descricao" item-value="idcaixa"
                label="Caixa" single-line />
            </v-col>
            <v-col cols="4">
              <v-select v-model="editarPagamento.idconta" :items="contas" item-text="descricao" item-value="idconta"
                label="Conta" single-line />
            </v-col>
            <v-col cols="4">
              <v-select v-model="editarPagamento.idformapagamento" :items="formasPagamento" item-text="descricao"
                item-value="idformapagamento" label="Forma de Pagamento" single-line />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea filled name="input-7-4" label="Observação" v-model="editarPagamento.observacao" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" style="align-content: center; text-align: right;">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="blue" @click="aplicarDataPagamentoHoje()">
                    mdi-calendar-today-outline
                  </v-icon>
                </template>
                <span>Aplicar a data de hoje</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <DialogData titulo="Data de pagamento:" v-model="editarPagamento.data_pagamento" disabled />
            </v-col>
            <v-col offset="1" cols="1" style="align-content: center; text-align: right;">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="blue" @click="aplicarValorTotal()">
                    mdi-share-all-outline
                  </v-icon>
                </template>
                <span>Aplicar valor total</span>
              </v-tooltip>


            </v-col>
            <v-col cols="4">
              <v-text-field prepend-inner-icon="mdi-currency-usd" label="Valor Efetivo"
                v-model="editarPagamento.valor_efetivo" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancelarEdicao()">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="salvarEdicao()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { format } from 'date-fns';
import DialogData from '@/components/dialogs/DialogData.vue';
import RelatorioView from '@/components/relatorios/RelatorioView.vue';

import { api } from '@/network/Api';
import { getApi, postApi } from '@/network/ApiService';


import mixin from '@/store/mixin.js';
import formatacaoMixin from '@/mixins/formatacao';

export default {
  components: {
    RelatorioView,
    DialogData,
  },

  created() {
    this.initialize();
  },
  mixins: [formatacaoMixin, mixin],
  data() {
    return {
      pagamentoLote: false,
      edicaoPagamentoLote: false,
      showProgresso: true,
      idregistro: this.$route.params.id,
      urlConsultarDados: api.contratoHistoricoAtual,
      idContratoHistorico: '',
      idContrato: '',
      dtAssinatura: '',
      dtValidadeInicio: '',
      ordem: '',
      dtValidadeInio: '',
      dtValidadeFim: '',
      valorTotal: '',
      qtParcela: '',
      observacao: '',
      contrato: '',
      contas: [],
      caixas: [],
      formasPagamento: [],
      coluna_ordenar: 'data_vencimento',
      pagamentos: [],
      search: '',
      headers: [
        { text: 'documento', value: 'documento' },
        { text: 'tipo', value: 'tipo' },
        // { text: 'data_movimentacao', value: 'data_movimentacao' },
        { text: 'Parcela', value: 'parcela' },
        { text: 'Vencimento', value: 'data_vencimento' },
        { text: 'Data Pagamento', value: 'data_pagamento' },
        { text: 'Valor Original', value: 'valor_original' },
        { text: 'valor Efetivo', value: 'valor_efetivo' },
        { text: 'valor Restante', value: 'valor_restante' },
        { text: 'Ações', value: 'actions' },
      ],
      dialogoEditarPagamento: false,
      editarPagamento: [],
    };
  },

  methods: {
    async initialize() {
      try {
        this.showProgresso = true;
        let currentData = await getApi(this.urlConsultarDados + `/${this.idregistro}`);

        this.idContratoHistorico = currentData.data.idcontratohistorico;
        this.idContrato = currentData.data.idcontrato;
        this.dtAssinatura = currentData.data.dt_assinatura;
        this.ordem = currentData.data.ordem;
        this.dtValidadeInicio = currentData.data.dt_validade_inicio;
        this.dtValidadeFim = currentData.data.dt_validade_fim;
        this.valorTotal = currentData.data.valor_total;
        this.qtParcela = currentData.data.qt_parcela;
        this.observacao = currentData.data.observacao;
        this.contrato = currentData.data.contrato;
        this.pagamentos = currentData.data.pagamento;

        this.pagamentos.map(pagamento => {
          this.$set(this.pagamentos, 'selecionado', (pagamento.status_pagamento == 'CP'));

          pagamento.selecionado = (pagamento.status_pagamento == 'CP');

          if (pagamento.idformapagamento === null && pagamento.status_pagamento != 'CP') {
            pagamento.idformapagamento = this.contrato.idformapagamento_padrao;
          }
          if (pagamento.idconta === null && pagamento.status_pagamento != 'CP') {
            pagamento.idconta = this.contrato.idconta;
          }
        });

        let respostaContas = await getApi(api.contaListarTodas);
        let respostaCaixa = await getApi(api.caixaListarTodas);
        let respostaFormaPag = await getApi(api.formaPagamentoListarTodas);

        this.contas = respostaContas.data;
        this.caixas = respostaCaixa.data;
        this.formasPagamento = respostaFormaPag.data;

        this.showProgresso = false;

      } catch (err) {
        var msgErro = JSON.stringify(err);
        this.error = msgErro;
        this.showProgresso = false;
      };
    },

    aplicarDataPagamentoHoje() {
      this.editarPagamento.data_pagamento = format(new Date(), 'yyyy-MM-dd');
    },
    aplicarValorTotal() {
      this.editarPagamento.valor_efetivo = this.editarPagamento.valor_original;
    },
    cancelarPagamentoLote() {
      this.pagamentos.map(item => {
        item.selecionado = false;
      })

      this.pagamentoLote = false;
    },

    async salvarEdicaoLote() {
      this.showProgresso = true;
      try {
        let lista = this.pagamentos
          .filter(pagamento => pagamento.selecionado && pagamento.status_pagamento == 'NP')
          .map(item => item.idpagamento);

        this.initialize();
      } catch (e) { }
      this.showProgresso = false;

    },

    abrirPagamento(item) {
      this.dialogoEditarPagamento = true;
      this.editarPagamento.data_pagamento = format(new Date(), 'yyyy-MM-dd');
      this.editarPagamento.valor_efetivo = 0;
      this.editarPagamento = item;
    },

    cancelarEdicao() {
      this.dialogoEditarPagamento = false;
    },
    async salvarEdicao() {
      await postApi(api.pagamentoEditar, this.editarPagamento.idpagamento, this.editarPagamento);
      this.dialogoEditarPagamento = false;
      this.initialize();
    },
  },
  watch: {
    pagamentos: {
      handler: function (after) {
        this.edicaoPagamentoLote = after?.some(pagamento => pagamento.selecionado && pagamento.status_pagamento == 'NP');
      },
      deep: true,
    }
  }
};
</script>