<template>
  <div>
    <edita-dados
      :idregistro="idregistro"
      :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro"
      :nome_rota_listagem_dados="nome_rota_listagem_dados"
      :campos_formulario="formulario"
      :showProgresso="showProgresso"
      :formularioValido="!$v.formulario.$anyError"
      @saveCompleted="saveCompleted"
      @close="close">
      <v-row>
        <v-col cols="12">
          <p style="margin-bottom: -20px; color: #94896d">
            * {{ formulario.descricao.label }}:
          </p>

          <v-text-field
            type="text"
            ref="descricao"
            v-model="formulario.descricao.valor"
            autofocus
            color="#94896d"
            :error-messages="descricaoErrors"
            @input="$v.formulario.descricao.$touch()"
            @blur="$v.formulario.descricao.$touch()" />
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col v-for="(ability, k) in todasHabilidades.valor" cols="4">
          {{ ability.ability }}
        </v-col> -->
        <v-col cols="12">
          <v-data-table
            v-model="formulario.habilidades.valor"
            :headers="headersHabilidades"
            :items="todasHabilidades.valor"
            :single-select="false"
            item-key="idability"
            show-select
            class="elevation-1">
          </v-data-table>
        </v-col>
      </v-row>
    </edita-dados>
  </div>
</template>

<script>
  import mixin from '@/store/mixin.js';
  import EditaDados from '@/components/crud/EditaDados.vue';
  import {api} from '@/network/Api';
  import {getApi} from '@/network/ApiService';

  import {validationMixin} from 'vuelidate';
  import {required, minLength} from 'vuelidate/lib/validators';

  export default {
    created() {
      this.initialize();
    },
    mixins: [mixin, validationMixin],
    components: {
      EditaDados,
    },
    data() {
      return {
        idregistro: this.$route.params.id,
        titulo_formulario: 'Edição de perfil',
        apiAtualizarRegistro: api.perfilAtualizar,
        nome_rota_listagem_dados: 'Perfis',

        formulario: {
          descricao: {label: 'Descrição', nome_campo: 'descricao', valor: ''},
          habilidades: {label: 'Habilidades',nome_campo: 'habilidades', valor: [],
          },
        },
        todasHabilidades: {
          valor: [],
        },

        headersHabilidades: [
          {
            text: 'Permissão',
            value: 'ability',
            align: 'start',
            sortable: true,
          },
          { text: 'Descrição', value: 'descricao' },
        ],
        showProgresso: false,
      };
    },
    validations: {
      formulario: {
        descricao: {
          valor: {required, minLength: minLength(3)},
        },
      },
    },
    computed: {
      descricaoErrors() {
        const errors = [];
        if (!this.$v.formulario.descricao.valor.$dirty) return errors;
        !this.$v.formulario.descricao.valor.required &&
          errors.push('A descrição é requerida.');
        !this.$v.formulario.descricao.valor.minLength &&
          errors.push('A descrição deve possuir ao menos 3 caracteres.');
        return errors;
      },
    },
    methods: {
      initialize() {
        this.showProgresso = true;
        getApi(api.perfilConsultar, this.$route.params.id)
          .then((res) => {
            this.formulario.descricao.valor = res.data.descricao;
            this.formulario.habilidades.valor = res.data.abilities;
            
          })
          .catch((err) => {})
          .finally((res) => {
            setTimeout(() => {this.showProgresso = false;}, 100);
          });

        getApi(api.habilidadesConsultar)
          .then((res) => {
            this.todasHabilidades.valor = res.data;
          })
          .catch((err) => {})
          .finally((res) => {
            setTimeout(() => {this.showProgresso = false;}, 100);
          });
      },
      saveCompleted(item) {
        this.$router.push({name: 'Perfis'});
      },
      close() {
        this.$router.push({name: 'Perfis'});
      },
    },
  };
</script>

<style scoped></style>
