<template>
  <div>
    <v-card v-show="!showProgresso" max-width="1024" class="mx-auto my-10" style="background-color: #f8f9fa">

      <v-card-title style="background-color: #94896d;">
        <v-row>
          <v-col cols="10">
            <span class="text-h6" style="color: #FFFFFF">{{ titulo_formulario }}</span>
          </v-col>
          <v-col cols="1" offset="1">
            <v-icon large color="#fff" @click="imprimir">mdi-file-pdf-box</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <hr>

      <v-card-text style="padding-bottom: 0px;">
        <v-container>
          <v-row>

            <v-col cols="12">
              <v-alert v-if="error != undefined" color="#FBCC38" elevation="2" colored-border>
                {{ error }}
              </v-alert>

              <v-form ref="form">
                <slot></slot>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- Botões para salvar ou cancelar o processo de cadastro -->
      <v-card-actions>
        <v-spacer />

        <!-- Botão para cancelar o processo e não salvar os dados no banco de dados -->
        <v-btn color="#f5f4ec" dark class="mb-2" @click="close">
          <span style="color: #990000">Cancelar</span>
        </v-btn>

        <!-- Botão para salvar os dados do aluno no banco de dados -->
        <v-btn color="#990000" dark class="mb-2 mr-5" @click="salvar">
          <span style="color: #FFFFFF">Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
  </div>
</template>

<script>
import { postApi } from '@/network/ApiService';
import TelaCarregando from '@/components/TelaCarregando.vue';
export default {
  components: {
    TelaCarregando
  },
  props: {
    idregistro: {
      type: String,
      required: true
    },
    titulo_formulario: {
      type: String,
      required: true
    },
    apiAtualizarRegistro: {
      type: String,
      required: true
    },
    campos_formulario: {
      type: Array | Object,
      required: true
    },
    showProgresso: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      error: undefined,
    }
  },
  methods: {
    async salvar() {
      const valid = await this.$refs.form.validate()

      if (!valid) {
        this.error = 'Verifique os dados digitados!';
        setTimeout(() => { this.error = undefined; }, 4000);
        return;
      }


      let objetoCamposFormulario = {};
      if (Array.isArray(this.campos_formulario)) {
        for (let index = 0; index < this.campos_formulario.length; index++) {
          objetoCamposFormulario[this.campos_formulario[index].nome_campo] = this.campos_formulario[index].valor;
        }
      } else if (this.campos_formulario !== null) {
        for (let key in this.campos_formulario) {
          objetoCamposFormulario[key] = this.campos_formulario[key].valor;
        }
      }

      postApi(this.apiAtualizarRegistro, this.idregistro, objetoCamposFormulario)
        .then((res) => {
          this.$emit('saveCompleted', res.data);

        }).catch(err => {
          console.log('err');
          if (err.message == 'Request failed with status code 500') {
            this.error = 'Falha no servidor contate o administrador do sistema!';
            setTimeout(() => { this.error = undefined; }, 4000);
          }
        });
    },
    close() {
      this.$emit('close');
    },
    imprimir() {
      this.$emit('imprimir');
    }
  },
}
</script>

<style scoped>
.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>