// requestHandler.js
import axiosInstance from '@/network/index.js';
import {api} from '@/network/Api';

// Função para fazer uma requisição GET a uma URL específica pelo seu nome
export function realizarLogin(email, password) {
  let dadosLogin = { email, password };

  return new Promise((resolve, reject) => {
    axiosInstance.post(api.login, dadosLogin)
      .then(res => {
        if (res.data.token != null && res.data.token != undefined) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('usuario', res.data.role);
          resolve(res)
        } else {
          reject('Falha ao obter o token!');
        }
      })
      .catch(err => {
        let status = err.response.status;
        let message = err.response.data.message;
        if (err.response.status == 500) {
          message = 'Ocorreu um erro no servidor, tente novamente mais tarde!';
        }
        reject({ status, message });
      });
  });
}


export function realizarLogout() {
  return new Promise((resolve, reject) => {
    axiosInstance.post(api.logout)
      .then(res => {
        localStorage.setItem('token', '');
        localStorage.setItem('usuario', '');
        resolve(res)
      })
      .catch(err => {
        let status = err.response.status;
        let message = err.response.data.message;
        if (err.response.status == 500) {
          message = 'Ocorreu um erro no servidor, tente novamente mais tarde!';
        }
        reject({ status, message });
      });
  });
}

export function excluirPerfil(id) {
  return new Promise((resolve, reject) => {
    axiosInstance.delete(api.perfilExcluir+"/"+id)
      .then(res => {
        localStorage.setItem('token', '');
        localStorage.setItem('usuario', '');
        resolve(res)
      })
      .catch(err => {
        let status = err.response.status;
        let message = err.response.data.message;
        if (err.response.status == 500) {
          message = 'Ocorreu um erro no servidor, tente novamente mais tarde!';
        }
        reject({ status, message });
      });
  });
}

export function getApi(url, id = null, filtros, headers) {
  let urlConsulta = url;
  if(id!=null) {
    urlConsulta = url + "/" + id;
  }

  return new Promise((resolve, reject) => {
    axiosInstance.get(urlConsulta, { params: filtros }, headers)
      .then(res => {
        resolve(res)
      })
      .catch(err => {       
        reject(err);
      });
  });
}

  export function excluirApi(url, id) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(url + "/" + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {       
          reject(err);
        });
    });
}

export function postApi(url, id, corpo) {
  let urlConsulta = url;
  if(id!=null) {
    urlConsulta = url + "/" + id;
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post(urlConsulta, corpo)
      .then(res => {
        resolve(res)
      })
      .catch(err => {       
        reject(err);
      });
  });
}